/* Classes for editor.tsx and profie.tsx */
.editorContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.editorForm,
.editorImageColumn,
.editorFields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.editorImageColumn {
  justify-items: center;
  align-items: center;
  border-right: 1px solid #2c80ba;
  height: 100%;
}

.editorRow {
  display: flex;
  gap: 1rem;
}

.editorImageBG {
  background: #2c80ba;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
}

.editorFieldBox {
  display: flex;
  justify-content: start;
  font-family: 'Poppins', sans-serif !important;
  border-bottom-width: 0 !important;
  font-weight: bold !important;
}

.editorFieldLabel {
  margin: 0;
  width: 200px;
  font-weight: normal;
  font-family: 'OpenSans', sans-serif;
}

.editorTextResponsive {
  margin: 0;
  font-weight: bold;
  text-align: left;
  text-wrap: normal;
}

.headerContainer {
  margin-bottom: 30px;
}

.editorCheckbox {
  display: flex;
  justify-content: start;
  width: 100%;
}

.box {
  width: 90%;
}

.buttonContainer {
  display: flex;
  column-gap: 1rem;
  justify-content: flex-end;
}

.label {
  margin-left: 0 !important;
  font-family: 'OpenSans', sans-serif;
  font-weight: normal !important;
}

.labelValue {
  width: 220px;
  margin-left: 0 !important;
  font-family: 'OpenSans', sans-serif;
  font-weight: bold !important;
  margin-right: 100px;
}

.homeLabel {
  font-weight: bold;
  width: 240px;
  margin-left: 0 !important;
}

.sectionLabel {
  font-weight: bold;
  color: #0c78c2;
  margin: 10px 0 5px 0;
  font-family: 'Montserrat', sans-serif !important;
  background-color: #f7fbfd;
  width: 100%;
  padding: 5px;
}

.mainTableContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  margin: 0 20px 0 20px;
}

.labelTab {
  text-transform: none !important;
  font-family: 'Poppins', sans-serif !important;
}

.textFieldBox {
  display: flex;
  justify-content: space-between;
  font-family: 'Poppins', sans-serif !important;
  border-bottom-width: 0 !important;
  font-weight: bold !important;
}

.textFieldTitleBox {
  font-family: 'Poppins', sans-serif !important;
  border-bottom-width: 0 !important;
}

.textTableTitleBox {
  font-family: 'Poppins', sans-serif !important;
  border-bottom-width: 0 !important;
  width: 100%;
}

.textFieldCIPSearch {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
  margin-bottom: 24px;
}

.stepLabel {
  display: flex;
  flex-direction: column;
  color: #67b3e0 !important;
}

.connector {
  z-index: 0;
  justify-content: center;
  background-color: #e9e9e9;
  width: 100%;
  height: 8px;
}

.textField {
  height: 40px;
  width: 300px;
}

.cipField {
  width: 25vw;
}

.cipFieldGrey {
  width: 25vw;
  height: 100%;
  background-color: #f5f3f3;
}

.roleIcon {
  padding-right: 10px;
  width: 25px;
}

.select {
  width: 400px;
  height: 40px;
}

.checkBox {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.cipContainer {
  display: flex;
  width: max-content;
  justify-content: flex-end;
}

.searchButton {
  position: relative;
  margin-left: 12px;
}

@media (max-width: 1600px) {
  .textFieldBox {
    width: auto;
  }
  .select {
    width: auto;
  }
}

@media (max-width: 630px) {
  .editorFieldLabel,
  .editorTextResponsive {
    width: 50%;
  }
  .textFieldBox {
    width: 100%;
    text-align: left;
  }

  .checkBox {
    width: 100%;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}

.rowBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin: 30px 0 10px 0;
}

.radioContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.radioLabel {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.radioLabel:first-child {
  margin-right: 50px;
}

input[type='radio'] {
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #999;
  border-radius: 50%;
  outline: none;
  transition: 0.3s;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type='radio']:checked:before {
  background: #f18b24;
}

.textFieldNoBorder {
  border: none !important;
}

.textFieldNormalText {
  color: black !important;
  opacity: 1 !important;
}

.textBackground {
  background-color: #f7fbfd;
}

.cellColumn {
  display: flex;
  flex-direction: row;
  width: 50%;
  border-bottom-width: 0 !important;
  margin: 10px 0 10px 0;
}

.cellColumn2 {
  display: flex;
  flex-direction: row;
  width: 50%;
}

.textFieldSteps {
  background-color: #eeefee;
  width: 80% !important;
}

.labelCard {
  margin-left: 0 !important;
  font-family: 'OpenSans', sans-serif;
  font-weight: normal !important;
  width: 30%;
}

@media (max-width: 599px) {
  .editorRow {
    flex-direction: column;
  }
  .editorImageColumn {
    padding-bottom: 1rem;
    border-right: none;
    border-bottom: 1px solid #2c80ba;
  }
  .label {
    width: 35vw;
    margin-left: 0 !important;
    font-family: 'Poppins', sans-serif;
    margin-right: auto;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .imagenRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #0c78c2;
    padding-bottom: 20px;
  }

  .textField {
    width: 55%;
  }

  .textResponsive {
    font-weight: bold;
    text-align: left;
    text-wrap: normal;
  }

  .containerColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}
