.map {
  background-color: #ffffff;
  box-shadow: -1px -5px 20px rgba(0, 0, 0, 0.15);
  margin: 0 20px 15px;
  font-size: calc(10px + 2vmin);
  height: 100%;
  width: 100%;
}

.ol-unselectable {
  display: block !important;
}

.ol-layers {
  display: block !important;
}

.olPopup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #3d87ba;
  bottom: 40px;
  left: -50px;
  min-width: 280px;
  font-size: 10px;
  display: none;
}

.olPopup::after,
.olPopup::before {
  top: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.olPopup::after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.olPopup::before {
  border-top-color: #3d87ba;
  left: 48px;
  margin-left: -11px;
}

.olPopupCloser {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.olPopupCloser::after {
  content: '✖';
  font-size: 25px;
}

@media (max-width: 900px) {
  .map {
    box-shadow: none;
    height: 500px;
    padding: 5px;
    margin: 0;
  }
}
