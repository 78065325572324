.headerCell {
  background-color: #ffffff;
  font-size: 14px;
  border-bottom: 3px solid #68b3e0;
}

.cell {
  font-weight: bold;
  color: #515151;
}
