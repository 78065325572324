.modalView {
  flex-direction: column;
  display: flex;
  background-color: var(--color-white);
  gap: 1.5rem;
  padding: 1.5rem;
  min-height: 100vh;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  padding: 0.25rem 0 0.25rem 0.75rem;
  gap: 1rem;
  font-size: 14px;
}

.rowContainer div {
  width: 100%;
}

.eventContainer {
  padding: 0.75rem;
  border: 1px solid rgb(189, 189, 189);
  border-radius: 10px;
}

.label {
  font-weight: bold;
  width: 50% !important;
}

.actionBtns {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
