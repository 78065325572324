.select {
  min-width: 180px;
  font-family: 'Poppins', sans-serif !important;
}

.checkbox {
  display: inline-block;
  font-family: 'Poppins', sans-serif !important;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  line-height: 1.8em;
}

.text {
  display: block; /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  line-height: 1.8em;
}

.hourPicker {
  margin-right: 0px !important;
  border: 1px solid #d1d1d1 !important;
  padding: 4px 8px !important;
  border-radius: 4px !important;
  width: 120px !important;
  font-family: 'Poppins', sans-serif !important;
}

.headerWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1em;
}

.headerInputRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}

.labelHeaderInput{
  min-width: 100px;
}

@media (max-width: 599px){
  .headerWrapper {
    flex-direction: column;
  }
  .headerInputRow {
    width: 100%;
    justify-content: space-between;
  }
}