.dateContainer {
  gap: 10px;
  display:flex; 
  justify-content: flex-end;
  margin-bottom: 3px;
}
.tabs {
  border-right: 1px solid #808080;
}

.tab {
  align-items: flex-start !important;
}

.datePicker {
  width: 175px;
}

p {
  margin: auto 10px auto 10px;
}

.chart {
  display: flex;
  flex-flow: row;
  margin: 10px;
}

@media (max-width: 740px) {
  .chart {
    width: auto !important;
  }
}

@media (min-width: 800px) {
  .chart {
    width: 600px !important;
  }
}

@media (min-width: 900px) {
  .chart {
    width: 450px !important;
  }
}

@media (min-width: 1280px) {
  .chart {
    width: 600px !important;
    height: auto !important;
  }
}

@media (max-width: 1200px) {
  .chartContainer {
    width: auto !important;
    flex-direction: column;
  }
}

@media (max-width: 550px) {
  .dateContainer {
    flex-direction: column;
  }
  .datePicker {
    margin-bottom: 10px;
  }
}

.cardProfile{
    border: 1px solid #68B3E0 !important;
    box-shadow: 0 4px 5px 0 #00000026 !important;
    background-color: #f7fbfd !important;
    margin-right: 8px;
    height: 100%;
}

.card{
    margin-right: 8px;
    height: 100%;
}

.marginCard{
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.images{
    width: 80px;
    height:80px;
    margin-bottom:20px
}
.containerCard{
    height: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.divider{
    margin: 0 20px;
    height: 40px;
}

.profileText{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: #000000;
}
