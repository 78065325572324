.newConversationContainer {
    flex-direction: column;
    min-height: 300px;
    width: 700px;
    display: flex;
    background-color: var(--color-white);
    padding: 1.5rem;
}

.label {
    width: 25%;
    font-weight: bold;
}

.roleIcon {
    padding-right: 10px;
    width: 25px;
}

.chip {
    margin: 3px 5px;
}

.exitIcon {
    display: flex;
    justify-content: flex-end;
}

.icon {
    cursor: pointer
}

.videoCallContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 15px 0 15px 0;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.imputsSize {
    width: 75%;
}

/*resolucion mobil*/
@media (max-width: 599px) {
    .newConversationContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        background-color: var(--color-white);
        padding: 10px;
    }

    .imputsSize {
        width: 60%;
    }

    .label {
        width: 100%;
        font-weight: bold;
        margin-right: 10px;
    }

    .buttonContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 0 0 0 10px;
    }
}
