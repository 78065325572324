.pageContainer {
    background: #ffffff;
    margin: 25px;
    box-shadow: -1px -5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 15px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
}

.actionBtns {
    display: none;
}
.containerRowTime{
    display: flex;
    justify-content: space-between;

}
.containerTableRow{
    display: flex;

}
.checkBoxTable{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 10px 0 0;
}

@media (max-width: 599px) {
    .actionBtns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
        margin-top: 1rem;
    }

    .addBtn {
        display: none;
    }
}