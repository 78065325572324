.currentNavHeaderContainer {
  display: flex;
  justify-content: center;
  background-color: var(--color-white);
  margin-bottom: 20px;
}

.currentNavHeaderContainer .innerContainer {
  width: 100%; /* Configura el ancho del contenedor interno al 80% */
}
.subtitle {
  font-family: 'Poppins', sans-serif !important;
  color: var(--color-gray) !important;
}

.currentNavHeaderContainer > * {
  margin: 0;
}

h2 {
  color: var(--color-gray);
}

.submenuContainer {
  margin-left: 50px;
  margin-right: auto;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.submenuOption {
  flex-basis: 50%;
  font-weight: bold !important;
  color: var(--color-gray);
}

.configurationItemLabel {
  margin: 5px;
}

.icon {
  margin-right: 15px;
}

.configurationLink {
  color: rgb(71, 71, 71);
  text-decoration: none;
}

.pathologiesContainer {
  height: 60px;
  display: flex;
  background-color: rgba(104, 179, 224, 0.2);
  justify-content: center;
  text-align: center;
  padding: 0 8px 0 8px;
  border-radius: 4px;
}

.circleContainer {
  height: 60px;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0 8px 0 8px;
  border-radius: 4px;
}

.circleContainer img {
  width: 40px;
}

.circleContainer p {
  color: #68b3e0;
  font-family: var(--p1-paragraph-16-regular-font-family);
  font-size: var(--p1-paragraph-16-regular-font-size);
  font-style: var(--p1-paragraph-16-regular-font-style);
  font-weight: var(--p1-paragraph-16-regular-font-weight);
  letter-spacing: var(--p1-paragraph-16-regular-letter-spacing);
  line-height: var(--p1-paragraph-16-regular-line-height);
  white-space: nowrap;
  width: fit-content;
}

.pathologies {
  color: #ffff !important;
  background-color: #0063A6 !important;
}

.layoutContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pathologyLayout{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.titleComponent{
  color: #0063A6;
}

.showMore{
  display:flex;
  cursor:pointer;
}

@media (max-width: 600px) {
  .currentNavHeaderContainer {
    height: 6rem;
  }
  h1 {
    font-size: 20px !important;
  }
  h2 {
    display: flex;
    font-size: 15px !important;
    justify-content: center;
  }
  .circleContainer img {
    width: 30px;
  }
  .circleContainer {
    height: 50px;
    display: flex;
    justify-content: center;
    padding: 0 8px 0 8px;
    border-radius: 4px;
  }
  .pathologiesContainer {
    height: 50px;
    display: flex;
    background-color: rgba(104, 179, 224, 0.2);
    justify-content: center;
    padding: 0 8px 0 8px;
    border-radius: 4px;
  }
  .pathologiesContainer h4 {
    font-size: 12px;
  }

  .pathologiesContainer {
    display: none;
  }

  .layoutContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 0 15px;
  }

  .pathologyLayout{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .pathologies {
    color: #ffff !important;
    background-color: #0063A6 !important;
  }

  .titleComponent{
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    color: #1462a2;
  }
}


