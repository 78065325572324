.containerMain{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}
.containerRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.containerRowGroup{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.containerRowModalSG{
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start;
    height: auto;
    width: 100%;
    margin: 15px 0 15px 0 !important;
}

.containerRowModalNotMobile{
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    marigin: 0;
}

.containerRowFinalDG{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    margin-bottom: 50px;
}

.containerRowSub{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    margin-top: 20px;
}

.containerRowButtonModal {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 20px;
}

.containerRowButtonModalSG{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.containerColumn{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.containerColumnSG{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.containerColumnSub{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    margin-bottom: -50px;
    padding-bottom: -50px;
}

.flexGrowOne{
    display: flex;
    flex-grow: 1;
    height: 100%;
    padding-bottom: -50px;
}

.flexGrowOneOne{
    display: flex;
    flex-grow: 1;
    height: auto;
}

.flexGrowTwo{
    display: flex;
    flex-grow: 2;
    height: auto;
}

.flexGrowThree{
    display: flex;
    flex-grow: 3;
    height: auto;
}

.flexGrowFour{
    display: flex;
    flex-grow: 4;
    height: auto;
}

.flexGrowTwoSub{
    display: flex;
    flex-grow: 2;
    width: 100%;
}

.cardContainer{
    display: flex;
    justify-content: start;
    align-items: flex-end;
    padding: 20px;
    margin: 10px;
    border-color: #68b3e0;
    border-style: solid;
    border-width: 2px;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    color: #68b3e0;
    font-weight: bold;
    font-family: Poppins;
}

.cardContainer:hover{
    color:  #f18b24;
    border-color: #f18b24;
    background-color: #fff9f4;
}

.cardContainerBlue{
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 20px;
    margin: 10px;
    border-color: #68b3e0;
    border-style: solid;
    border-width: 2px;
    border-radius: 15px;
    background-color:#f8fbfe;
    width: 100%;
    height: 100%;
    color: #68b3e0;
    font-weight: bold;
    font-family: Poppins;
}

.cardContainerBlue:hover{
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 20px;
    margin: 10px;
    border-style: solid;
    border-width: 2px;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    color:  #f18b24 !important;
    font-weight: bold;
    font-family: Poppins;
    border-color: #f18b24;
    background-color: #fff9f4;
}


.blackBold{
    color:  black !important;
    font-weight: bold;
    margin-bottom: 10px;
}

.blackBoldWeekday{
    font-weight: bold;
    font-family: Poppins;
    margin: 5px 0 5px 20px;
    width: 100px;
}

.black{
    color:  black !important;
    font-weight: normal !important;
    margin-left: 20px;
}

.cardTitleBig{
    color: #68b3e0;
    font-weight: bold !important;
    font-family: Poppins !important;
    font-size: xx-large !important;
    margin: 30px 0 50px 0 !important;
    padding-bottom: 20px;
    border-bottom-style: solid;
    border-bottom-color: #f18b24;
    border-bottom-width: 1px;
    width: 70%;
}

.containerRowButton{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 50px;
}

.containerRowButton :Disabled {
    display: none;
}

.containerButtonSpace {
    display: flex;
    flex-direction: row;
    margin: 10px;
    padding: 0px;
    height: 100%;
}

.modalMain{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    justify-items: center;
    overflow-y: auto;
}

.containerModal {
    display: flex;
    flex-direction: column;
    margin: 20%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    width: 45vw;
    height: auto;
    position: fixed;

}

.containerModalSG {
    display: flex;
    flex-direction: column;
    margin: 10%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    width: 80vw;
    height: fit-content;
}

.contentHeader {
    display: flex;
    justify-content: flex-end;
    margin: 8px;
    color: #68b3e0;
}

.headerTitle {
    font-family: 'Poppins', sans-serif !important;
    font-weight: bold !important;
    font-size: 18px !important;
    padding: 15px;
}

input[type='radio']:checked:before {
    background: #f18b24;
}

.content {
    margin: 20px 0 20px 30px;

}

.checkboxContainer {
    margin-bottom: 20px;
}

.check{
    margin-right: 10px;
    margin-bottom: -5px;
}

.checkCustom{
    margin-right: 10px;
    margin-bottom: -5px;
    width: 100%
}

.checkModal{
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 100%
}

.dividerHeader{
    margin-right: 15px;
    margin-left: 15px;
    border-color: #f47105;
    margin-bottom: 15px;
}

.bottonMarginLeft{
    margin-left: 20px;
    margin-right: 10px;
    font-weight: bold;
}

.containerRowHeaderSG{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0 20px 0;
    width: 100%;
}

.containerRowSubtitle{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 100% ;
    width: 100%;
}

.colorNameGroup{
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    color: white;
    font-size: 30px;
    font-family: Poppins;
    font-weight: bold;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    width: 200px;
    height: 100%;
    padding: 0 10px 0 10px !important;
}

.nameGroup {
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.mainTabs {

    margin: 0 25px 0 25px;
}

.pageContainer {
    background: #ffffff;
    box-shadow: -1px -5px 20px rgba(0, 0, 0, 0.1);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 25px;
}


.containerWrapper {
    height: 80vh;
    overflow-y: auto;
    padding: 1rem 0;
}

.marginTime {
    padding-right: 50px;
    border-right-style: solid;
    border-right-color: #e2e2e2;
    border-right-width: 1px;
    margin: 0 0 0 15px;
}

.marginNumbers {
    padding-right: 50px;
    margin: 0 0 0 15px;
}

.containerColumnModalOne{
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    height: auto;
    width: auto;
    padding: 5px 0 5px 30px;
    border-style: solid;
    border-color: #e2e2e2;
    border-width: 1px 0 1px 0;
}

.containerColumnModalTwo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    height: auto;
    width: auto;
    padding: 5px 0 5px 0;
    border-style: solid;
    border-color: #e2e2e2;
    border-width: 1px 0 1px 0;
}

.containerColumnModalFinal{
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    height: 100%;
    width: 18%;
    padding: 5px 0 5px 0;
    border-style: solid;
    border-color: #e2e2e2;
    border-width: 1px 1px 1px 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.mapBorder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    border-style: solid;
    border-color: #68b3e0;
    border-width: 1px;

    height: 600px;
    width: 100%;
    margin: 0 30px 0 0;
    padding: 0 0 0 0;
}

.scrollContainer{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 558px;
}

@media(max-width: 1230px){
    .mainTabs{
        margin: 0 5px 100px 5px;
    }

    .containerMain{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
    }

    .cardContainerBlue{
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding: 20px;
        margin: 10px 0 10px 0;
        border-color: #68b3e0;
        border-style: solid;
        border-width: 2px;
        border-radius: 15px;
        background-color:#f8fbfe;
        width: 100%;
        height: 100%;
        color: #68b3e0;
        font-weight: bold;
        font-family: Poppins;
    }


    .containerRow{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
        width: 100%;
    }

    .containerRowGroup{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
    }

    .containerRowSub{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
        margin: 10px 0 0 0;
    }

    .cardContainer{
        display: flex;
        justify-content: start;
        align-items: flex-end;
        padding: 20px;
        margin: 10px 0 10px 0 ;
        border-color: #68b3e0;
        border-style: solid;
        border-width: 2px;
        border-radius: 15px;
        width: 100%;
        height: 100%;
        color: #68b3e0;
        font-weight: bold;
        font-family: Poppins;
    }

    .containerModal {
        display: flex;
        flex-direction: column;
        margin: 0;
        background-color: #fff;
        padding: 10px;
        width: 100vw;
        height: auto;
        position: fixed;

    }

    .containerModalSG {
        display: flex;
        flex-direction: column;
        margin: 5px;
        background-color: #fff;
        border-radius: 10px;
        padding: 5px;
        width: 100vw;
        height: auto;
        overflow:hidden
    }

    .mapBorder{
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        border-style: solid;
        border-color: #68b3e0;
        border-width: 1px;
        height: 450px;
        width: 100%;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
    }

    .scrollContainer{
        max-height: 400px;
    }

    .flexGrowFour{
        display: flex;
        flex-grow: 4;
        height: auto;
    }

    .containerRowModalSG{
        display: flex;
        flex-direction: column !important;
        justify-content: flex-start;
        height: auto;
        width: 100%;
        margin: 15px 0 15px 0 !important;
    }

    .containerRowModalSGCustom{
        display: flex;
        flex-direction: row !important;
        justify-content: flex-start;
        height: auto;
        width: 100%;
        margin: 0 0 0 0 !important;
        border-style: solid;
        border-color: #e2e2e2;
        border-width: 0 1px 1px 1px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

    }

    .containerRowCustom{
        display: flex;
        flex-direction: row !important;
        justify-content: flex-start;
        height: auto;
        width: 100%;
        margin: 15px 0 15px 0 !important;
    }

    .containerRowModalSGFinal{
        display: flex;
        flex-direction: column !important;
        justify-content: flex-start;
        height: auto;
        width: 100%;
        margin: 15px 0 100px 0 !important;
    }

    .colorNameGroup{
        display: flex;
        flex-direction: column;
        justify-items: center;
        justify-content: center;
        color: white;
        font-size: 30px;
        font-family: Poppins;
        font-weight: bold;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 0;
        width: 100%;
        height: 100%;
        padding: 0 10px 0 10px !important;
    }

    .containerColumnModalOne{
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        height: auto;
        width: 100%;
        padding: 5px 0 5px 0;
    }

    .containerColumnModalTwo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        height: auto;
        width: 100%;
        padding: 5px 0 5px 0;
    }

    .containerColumnModalFinal{
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        height: 100%;
        width: 10%;
        padding: 5px 10px 5px 10px;

    }

    .marginTime {
        padding-right: 50px;
        border-right-width: 0;
        margin: 0 0 0 15px;
    }

    .flexGrowOneOne{
        display: flex;
        flex-grow: 1;
        height: 550px;
    }

    .containerRowButtonModalSG{
        display: flex;
        flex-direction: row;
        justify-content:space-around;
        margin-bottom: 0;
        padding: 1rem 0;
    }

    .containerRowModalNotMobile{
        display: flex;
        flex-direction: column !important;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
        margin: 0;
    }

    .flexGrowThree{
        display: flex;
        flex-grow: 3;
        height: auto;
        margin: 0 0 30px 0;
    }
    .containerColumnSG{
        padding-top: 1rem;
    }
}

.css-163xi74-MuiInputBase-root-MuiOutlinedInput-root{
    height: 30px !important;
}
