@media (max-width: 599px) {
    .box {
        padding: 0!important;
        margin: 0!important;
        box-shadow: none;
        border-radius: none;
    }
    .card {
        box-shadow: none!important;
        border-radius: 0!important;
        padding-left: 0!important;
        padding-right: 0!important;
    }
}