.calendarContainer {
  column-gap: 2rem;
  margin-bottom: 2rem;
  position: relative;
}

.headerContainer {
  text-align: left;
  width: 90%;
  margin: auto;
}

@media (max-width: 349px) {
  .calendarContainerLeft {
    width: 280px;
  }
}

@media (min-width: 350px) {
  .calendarContainerLeft {
    row-gap: 1rem;
    height: fit-content;
    max-width: 320px;
  }
}

.datePicker {
  background-color: #0062a6;
}

.MuiPickersToolbar .toolbar {
  background-color: #0062a6;
}

.calendarContainerLeft {
  display: block !important;
  text-align: center !important;
  background: #fff;
  padding: 0;
  border-radius: 3px;
  box-shadow: -2px -2px 8px rgba(0, 0, 0, 0.2);
}

.calendarContainerLeft > button {
  width: 45% !important;
  position: relative !important;
  margin: 4px;
  height: 100px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.calendar {
  background: #fff;
  /* border-radius: 6px 6px 3px 3px; */
  overflow: hidden;
  width: auto;
  border: 1px solid #68b3e0;
  margin-left: 1em;
  margin-right: 1em;
  font-family: 'Poppins', sans-serif !important;

  position: absolute;
  left: 0;
  right: 0;
  z-index: 0!important;
  margin-top: 63px;
}

.legend {
  display: flex;
  flex-direction: column;
  background: #fff;

  margin-top: auto;
}

.legend1 {
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-right: 0.5em;
  margin-left: 0.5em;

}

.tabsCalendar {
  position: absolute!important;
  z-index: 1!important;
  width: 100%;
}


/*Resolucion PC*/
@media (min-width: 598px) {

  .tabsCalendar {
    margin-right: 2em;
    margin-left: 2em;
    padding: 0 !important;
  }

  .showCalendar {
    border-bottom: 3px solid #f88c24;
    padding-bottom: 10px;
    font-size: 30px;
  }

  .titleCalendar{
    padding-bottom: 0;
    display: inline-block;
    margin: 0;
    padding: 0;
  }

  .createEvent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background: #fff;
    margin-right: 2em;
    margin-left: 2em;
    margin-bottom: 2em;
    padding: 0 5px 0 5px !important;
  }

  .calendar {
    background: #fff;
    /* border-radius: 6px 6px 3px 3px; */
    overflow: hidden;
    width: auto;
    border: 1px solid #68b3e0;
    margin-left: 2em;
    margin-right: 2em;
    font-family: 'Poppins', sans-serif !important;
  }

  .legend {
    display: flex;
    flex-direction: column;
    background: #fff;
    margin-right: 2em;
    margin-left: 2em;
    margin-top: auto;
    padding: 5px 5px 0px 5px !important;
  }

  .legend1 {
    display: flex;
    flex-direction: column;
    background: #fff;
    margin-right: 2em;
    margin-left: 2em;
  }

  .textLegend {
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 10px;
  }

  .legendDiv {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: auto;
    margin-bottom: 10px;
    font-size: 12px;
  }

  .legendDiv1 {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 15px;
  }

  .legendCheck2 {
    display: flex;
    font-size: 25px;
    align-items: center;
    flex-wrap: wrap;
  }

  .labelCheck {
    font-size: 20px; /* Tamaño de fuente reducido para hacer el texto más pequeño */
    margin: 5px; /* Margen horizontal para separar labels entre sí, sin margen vertical */
    display: flex; /* Asegúrate de que el Checkbox y el texto se alinean correctamente */
    align-items: center; /* Alinea el contenido al centro verticalmente dentro del label */
    border: 1px solid #d2d2d2;
    border-radius: 20px;
    padding: 5px 20px;
  }

  .checkboxInput {
    display: none; /* Oculta el input checkbox por defecto */
  }

  .checkboxImage {
    width: 20px; /* Ancho de la imagen */
    height: 20px; /* Alto de la imagen */
    margin-left: 10px; /* Espacio entre la imagen y el texto */
    display: inline-block; /* Mostrar la imagen */
  }

  /* Estilos cuando el checkbox está marcado */
  .checkboxInput:checked + .checkboxImage {
    opacity: 1; /* Hacer la imagen completamente visible cuando está marcado */
  }

  /* Ocultar la imagen cuando el checkbox no está marcado */
  .checkboxInput:not(:checked) + .checkboxImage {
    display: none;
  }

  .legendCheck {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 10px;
  }
}

.img {
  height: 150px;
  margin-left: 10px;
}

.calendar header {
  box-shadow: none;
}

.calendar div[role='tablist'] {
  background-color: #efefef;
  color: #282828;
  box-shadow: 0 0 #000;
  border-bottom: 0 #000 solid;
}

.calendar div[role='tablist'] button[aria-selected='true'] {
  background-color: #fff;
  color: #282828;
  box-shadow: 0 0 #000;
  border-bottom: 0 #000 solid;
}

.calendar .MuiTabs-indicator {
  display: none !important;
}

.parsetabs span {
  font-weight: bold;
  text-align: left;
}

.parsetabsActive span {
  font-weight: bold;
  text-align: left;
}

.caltabs > div > span {
  top: 0;
  height: 4.5px;
}

a .calendar > div > div {
  margin: 10px;
}

@media (max-width: 1090px) {
  .calendarContainer {
    display: flex;
    flex-direction: column;
  }

  .calendarContainerLeft {
    row-gap: 1rem;
    height: fit-content;
    max-width: 320px;
    margin: 0 auto 20px auto;
  }

  .caltabs {
    visibility: visible;
  }
}

.font1 {
  width: 40px !important;
}

/* Estilo personalizado para el checkbox */
.inputCheckbox[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid black;
  width: 20px;
  height: 20px;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}

.inputCheckbox[type='checkbox']:checked {
  background-color: transparent;
  border-color: black;
}

.inputCheckbox[type='checkbox']:checked:after {
  content: '\2713'; /* Unicode character for checkmark */
  position: absolute;
  right: 1px; /* Ajusta según necesites para alinear el tick a la derecha */
  top: 50%;
  transform: translateY(-50%);
  color: red;
}

.tabCustom {
  text-transform: none!important;
  background-color: #f7fbfd !important;
  color: #AFD7EF !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  text-transform: none !important;
  padding: 20px 60px !important;
  font-style: normal !important;
  line-height: normal !important;
  font-family: 'Montserrat', sans-serif !important;
  border-radius: 10px 10px 0px 0px !important;

  background-color: #f7fbfd !important;
  color: #AFD7EF !important;
  border: 1px solid #68b3e0 !important;
}

.tabCustomActive {
  font-size: 18px !important;
  font-weight: 600 !important;
  text-transform: none!important;
  border: 1px solid #68b3e0 !important;

  background-color: white !important;
  color: #f18b24 !important;
  padding: 20px 60px !important;
  font-style: normal !important;
  line-height: normal !important;
  font-family: Montserrat, sans-serif !important;
  border-bottom: 1px solid white!important;
  border-radius: 10px 10px 0px 0px !important;
}

.filterCategory {
  font-size: 20px;
  padding: 0;
  margin-right: 30px;
  margin-left: 0;
}

.modalGetTrainingIntensity {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.trainingContainer{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fefefe;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;

}

.buttonContainer {
   display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  
}

.fieldRowButton {
  display: flex;
  gap: 5px;
}

.containerRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.titleIntesity{
  font-family: Poppins;
  font-weight: bold;
  font-size: 30px;
  color: #68b3e0;
  margin-bottom: 20px;
}

.titleIntensityDate{
  font-family: Poppins;
  font-weight: bold;
  font-size: 16px;
  color: #68b3e0;
  margin-bottom: 20px;

}

.textIntensity{
  font-family: Poppins;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 20px;
}

.sliderMobile {
  width: 550;
}

/*Resolucion Movil*/
@media (max-width: 599px) {

  .sliderMobile{
    width: auto;
  }

  .textIntensity {
    font-family: 'OpenSans';
    color: #515151;
  }

  .titleIntensityDate{
    font-family: 'OpenSans';
    color: #515151;
    font-weight: normal;
  }
  .titleIntesity {
    font-size: 23px;
  }

  .buttonContainer {
    display: flex;
    flex-direction: column; 
    align-items: center; 
  }
  
  .fieldRowButton {
    display: flex; 
    justify-content: center; 
    margin-bottom: 10px; 
    gap: 10px;
    
  }
  
 

  .mobileModalContainer {
    width: 100%;    
    height: calc(100% - 102px) !important;
    overflow-y: auto;
    padding: 23px 18px 32px 18px;
    margin:0;
    border-radius: 0!important;
    overflow: scroll;
    background-color: #ffffff;
    position:absolute;
    top: 0;
  }

  .mobileScrollContainer {
    overflow-y: scroll;
  }


  .tabsStyle{
    text-transform: none!important;
    width: 100%;
  }

  .titleCalendar{
    margin: 10px 0 10px 0;
    display: inline-block;
    border-bottom: 3px solid #f88c24;

  }

  .createEvent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #fff;
    margin: 0 0.5em 2em 0.5em;
  }

  .showCalendar {
    font-size: 20px;
  }

  .textLegend {
    margin-top: 7px;
    margin-left: 10px;
  }

  .legendDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 15px;
    padding: 0px;
  }

  .legendDiv1 {
    display: flex;
    flex-direction: row;
    /*justify-item: center;*/
    margin-top: 15px;
    width: 50%;
    font-size: 12px;
  }

  .legendCheck {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 10px;
  }

  .labelCheck {
    width: 50%;
    font-size: 12px;
  }

  .legendCheck2 {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  .tabsCalendar {
    width: 100%;
    padding: 0 !important;
    position: absolute!important;
    z-index: 1!important;
  }

  .calendar {  
    margin-top: 47px;
  }

  @media (max-width: 599px) {
    .tabCustom {
      padding: 5px 15px!important;
    }
  }
  /*  dispositivos pequeños */
  @media (max-width: 390px) {

    .mobileScrollContainer {
      max-height: 500px;

    }

  }
  
}