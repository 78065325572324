.newConversationContainer {
  margin: 1rem auto;
  background-color: #fff;
  border-radius: 4px;
  padding: 1.5rem;
  width: 500px;
}

.titleForm {
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  margin: 1rem 0;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.radioGroup {
  margin-left: 2px;
}

.actionBtns {
  display: flex;
  justify-content: end;
}

.field {
  width: 100%;
}

.field > div {
  font-family: 'Poppins', sans-serif !important;
}

.label > span {
  font-family: 'Poppins', sans-serif !important;
}

@media (max-width: 599px) {
  .newConversationContainer {
    margin: 0;
    border-radius: 0;
    width: 100%;
  }
}
