body {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  background-color: #ffffff;
  height: 50px;
}

.container {
  background-color: rgba(125, 173, 221, 0.44);
  min-height: calc(100vh - 80px);
}

.loginContainer {
  display: flex;
  justify-content: center;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.login {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 80px 30px 80px;
  background-color: #ffffff;
  border-radius: 20px;
  width: 600px;
}

.login h2 {
  color: #6e899c;
  font-weight: 400;
}

.login h5 {
  color: #2fa0ec;
  font-size: 14px;
}

.loginFields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

img {
  width: auto;
  height: auto;
}

.footer {
  position: fixed;
  bottom: 0 !important;
  right: 0 !important;
  left: 0 !important;
  padding: 10px;
  background: #166cb3 !important;
  height: 80px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.footer p {
  font-size: 10px;
  color: white;
}

.headerTop {
  width: 100%;
  height: 30px;
  position: relative;
  align-items: center;
  display: flex;
}

.headerTop p {
  margin: 0;
  color: var(--color-white);
}

.headerTop > div {
  width: 100%;
}

.logoHeader {
  position: absolute;
  /*  max-width: 800px;*/
  height: calc(100% + 40px);
}

.headerTopLeft {
  display: flex;
  justify-content: flex-start;
}

.headerTopLeft img {
  margin: 15px 0 0 1rem;
  width: 50px;
  height: 45px;
  z-index: 99;
}

.headerTopLeft p {
  margin: 0.3rem 0 -0.5rem 1rem;
  width: 380px;
  display: inline-block;
  z-index: 99;
  color: #ffffff;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
  letter-spacing: 2px;
}

.headerTopRight {
  height: 70px;
}

.headerTopRight img {
  margin: 30px 20px 0 0;
  width: auto;
  height: 25px;
  float: right;
}

.headerBottomMobile {
  display: none !important;
  height: 25px;
  background-color: #68b3e0 !important;
  align-content: center;
  box-shadow: none;
}

.nameIconMobile {
  width: 15px;
  margin: 5px 10px 5px 15px;
}

.menuLink {
  text-decoration: none;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
}

.menuIcon {
  width: 18px;
  margin-left: 5px;
}

.headerBottom {
  height: auto;
  margin-top: 1%;
}

.headerBottomLeft {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  column-gap: 1.5rem;
  row-gap: 1rem;
  min-height: 0 !important;
  background-color: var(--color-light-blue);
  border: 0.5rem solid var(--color-light-blue);
  justify-content: flex-start;
  position: relative;
}

.userLogo {
  height: 1.5rem;
  width: 1.5rem;
}

.headerBottomMiddle {
  column-gap: 0.3rem;
  row-gap: 1rem;
  padding: 0.5rem;
  background-color: var(--color-dark-blue);
  position: relative;
}

.headerBottomMiddle:before {
  content: '';
  position: absolute;
  top: 0;
  left: -50px;
  border-bottom: 44px solid var(--color-dark-blue);
  border-left: 50px solid transparent;
  width: 0;
}

.headerBottomMiddle > * {
  cursor: pointer;
}

.headerBottomRight {
  padding: 0.5rem;
  column-gap: 0.5rem;
  background-color: var(--color-orange);
  cursor: pointer;
  position: relative;
}

.headerBottomRight:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-top: 44px solid var(--color-dark-blue);
  border-right: 50px solid transparent;
  width: 0;
}

.icon {
  width: 20px;
}

.nameIcon {
  width: 20px;
  margin-right: 20px;
}

.genderIcon {
  width: 20px;
  margin-left: 20px;
}

.headerDropdown {
  transition: ease all 0.4s;
}

.unAuth {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.roleContainer {
  display: flex;
  flex-direction: column;
}

.role {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

/* Safari 10+ */

@media not all and (min-resolution: 0.001dpcm) {
    .headerTopLeft img {
      max-width: 170px;
    }

    .headerTopLeft p {
      max-width: 600px;
    }

    .headerTopRight img {
      max-width: 400px;
    }
}

@media (max-width: 1225px) {
  .headerBottomMiddle:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 0;
    border-left: 0;
    width: 0;
  }

  .headerBottomRight:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-top: 0;
    border-right: 0;
    width: 0;
  }
}

@media (max-width: 1600px) {
  .logoHeader {
    position: absolute;
    max-width: 800px;
    height: calc(100% + 40px);
    transition: 300ms;
  }
}

@media (max-width: 1300px) {
  .headerTopRight img:nth-child(2) {
    display: none;
  }

  .headerTopRight img:nth-child(1) {
    background: #ffffff;
  }
}

@media (max-width: 1000px) {
  .logoHeader {
    display: none;
  }

  .header {
    background-color: #166cb3;
  }

  .headerTop {
    background: #166cb3;
    margin-bottom: -10px;
  }

  .headerTopRight img:nth-child(1) {
    display: none;
  }

  .mainContainer {
    background: #39f !important;
  }
}

@media (max-width: 900px) {
  .headerTop {
    height: 60px;
    padding-bottom: 10px;
  }

  .headerTopLeft {
    justify-content: center;
  }

  .headerTopLeft p {
    width: 100%;
    font-size: 18px;
    line-height: 26px;
    padding-bottom: 13px;
  }

  .headerBottomLeft {
    display: none !important;
  }

  .headerBottomMiddle {
    display: none !important;
  }

  .headerBottomRight {
    display: none !important;
  }

  .headerBottomRight p {
    display: none !important;
  }

  .headerTopLeft img {
    display: none;
  }

  .headerTopRight {
    display: none !important;
  }

  .headerBottomMobile {
    display: flex !important;
  }
}

@media (max-height: 700px) {
  .footer {
    display: none !important;
  }
}

@media (max-width: 630px) {
  .login {
    width: 43vh;
    padding: 30px;
  }
  .login h5 {
    margin: 0.8rem;
  }
}
