.header {
  margin-top: 0;
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 10px;
  border-bottom: 3px solid var(--color-light-blue);
  margin-bottom: 30px;
}

.treatCardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.treatCard {
  display: inline-block;
  padding: 1rem;
  max-height: 500px;
  max-width: 700px;
  margin: 50px;
}

.dataButtonContainer {
  margin: 10px;
  padding: 10px;
}

.accordionDetails {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.accordionSummary {
  background-color: var(--color-light-blue) !important;
  color: white !important;
  border-radius: 10px !important;
  margin-bottom: 10px !important;
}

.dataButton {
  height: 50px;
  width: 200px;
}

.symptomContainer {
  display: flex;
  align-items: center;
  justify-content: left;
}

.genderIcon {
  width: 20px;
  margin-left: 5px;
}

.listContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.forwardIcon {
  margin-left: 1rem;
}

.listBlock {
  display: inline-flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  max-width: 280px;
  background: #68b3e0;
  border-radius: 6px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  height: 60px;
  margin: 10px;
  overflow: hidden;
}

.iconBlock {
  height: 60px;
  width: 60px;
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 24px;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex: 1;
}

.centerBlock {
  text-align: left;
  display: inline-block;
}

.textBlock {
  color: #000000;
  margin-left: 30px;
  font-size: 21px;
  margin-bottom: 50px;
  width: 120px;

}

.centerBlock2 {
  text-align: left !important;
  display: block !important;
  margin-left: 150px;
  margin-right: 150px;
}

.centerBlock3 {
  text-align: center !important;
  display: block !important;
}

@media (max-width: 920px) {
  .centerBlock {
    display: inline-block;
    text-align: center;
  }

  .centerBlock2 {
    margin-left: 50px;
    margin-right: 50px;
  }
  
}

.tabHeader {
  text-transform: lowercase;
  text-transform: capitalize;
}

.cardContainer {
  box-shadow: none !important;
  width: 100%;
}

.hoverCard {
  border: 1px solid #68b3e0 !important;
  transition: border-bottom-color 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 8px !important;
  padding: 10px;
  box-shadow: 0px 4px 5px 0px #00000026;
  height: 290px;
}

.hoverCard:hover {
  border-color: #f18b24 !important;
  border-width: 4px !important;
  background: rgb(254, 249, 244);
}

.horizontalLine {
  height: 4px;
  width: 50%;
  background-color: #f18b24;
  border: none;
  margin-left: 0px;
}

.tabMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: #6db3dd 1px solid;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 20px;
}

.mainContainerOutTab {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.textFieldBox {
  font-family: 'Poppins', sans-serif !important;
  border-bottom-width: 0 !important;
  font-weight: bold !important;
}

.patientDataRow{
  margin: 10px 0;
}

/* resolucion Mobil */
@media (max-width: 599px) {
  .centerBlock2 {
    text-align: left !important;
    display: block !important;
    margin: 0 0 100px 0;
  }

  .hoverCard {
    border-bottom-width: 2px;
    transition: border-bottom-color 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    max-height: 250px;
  }

  .textBlock {
    justify-content: start;
    position: relative;
    padding-bottom: .8rem;
    font-size: 20px;
  }

  .textBlock::after {
    content: '';
    height: 4px;
    width: 50%;
    background-color: #f18b24;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .horizontalLine {
    display: none;
  }

  .tabMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    border-width: 0;
  }
}