.headerBtns {
  justify-content: space-between;
}

.actionBtns {
  justify-content: flex-start;
}

.selectTypeBox,
.headerBtns,
.actionBtns {
  display: flex;
  gap: 1rem;
}

.radioGroup {
  flex-flow: row wrap !important;
}
