.actionsTitle {
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  font-size: 24px !important;
}

.containerTable {
  width: 100%;
}

.containerActions {
  width: auto;
  min-width: 260px;
  margin-top: 30px;
  margin-right: 1.5rem;
}

.dividerStyleOne {
  border: 1px solid #f47105;
  margin-bottom: 20px;
  margin-top: 8px;
  width: 245px;
}

.dividerStyleTwo {
  border: 1px solid #dcdcdc;
  margin-bottom: 20px;
  margin-top: 8px;
  width: 245px;
}

.dividerStyleThree {
  border: 1px solid #dcdcdc;
  margin-bottom: 20px;
  margin-top: 25px;
  width: 245px;
}

.boxStyle {
  width: 250px;
  margin-top: 8px;
}
