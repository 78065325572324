.familiarDataBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 40px;
  margin: 0 0 2rem 0;
}

.growBoxContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 40px;
  margin: 0 0 2rem 0;
}

@media (max-width: 320px) {
  .growthBox {
    height: 95px;
  }
}

@media (min-width: 321px) {
  .growthBox {
    height: 92px;
  }
}

@media (min-width: 600px) {
  .growthBox {
    height: 68px;
  }
}

@media (min-width: 1024px) {
  .growthBox {
    height: 93px;
  }
}

@media (min-width: 1280px) {
  .growthBox {
    height: 50px;
    width: 70px;
  }
}

.titleMedicalData {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  border-bottom-color: #ee8c38;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  padding: 0 0 10px 0;
  margin: 0 0 20px 0;
}

.modalScrollContainer {
  height: 100vh;
  overflow-y: scroll;
}

.modalContainerTable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 10rem 10rem 0 10rem;
  padding: 15px;
  background-color: white;
  border-radius: 4px;
}

.titleModalTable {
  display: flex;
  flex-direction: row;
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  font-weight: bold;
}

.containerCardBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  justify-items: center;
  border: 2px solid #6db3dd;
  border-radius: 15px;
  width: 14vw;
  height: 14vw;
  margin: 10px 10px 10px 10px;
  background-color: white;
}

.containerCardBox:hover {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  justify-items: center;
  border: 2px solid #ee8c38;
  border-radius: 15px;
  width: 14vw;
  height: 14vw;
  margin: 10px 10px 10px 10px;
  background-color: #fef9f4;
}

.cardText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.mainLandmarkContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 20px 0 20px 0;
}

.imageBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.containerRow {
  display: none;
}

.modalHeaderRow{
display: flex;
justify-content: space-between;
border-bottom: 2px solid #ee8c38;
}

.pendingStatus {
  border-color: rgb(142, 8, 8) !important;
}

.completedStatus {
  border-color: rgb(24, 130, 24) !important;
}

.pendingStatusCustom {
  color: rgb(142, 8, 8) !important;
  border-color: rgb(142, 8, 8) !important;
}

.completedStatusCustom {
  color: rgb(24, 130, 24) !important;
  border-color: rgb(24, 130, 24) !important;
}

.tableActionStyle {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.displayNone {
  display: none;
}

.containerRowGrowMonitor {
  display: none;
}

/* resolucion mobil */
@media (max-width: 599px) {
  .containedRow,
  .containerRowGrowMonitor {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0 0 0;
  }

  .familiarDataBox,
  .growBoxContainer {
    display: none;
  }
  .containerCardBox {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    justify-items: center;
    border: 2px solid #6db3dd;
    border-radius: 15px;
    width: 32vw;
    height: 37vw;
    margin: 10px 10px 10px 10px;
    background-color: white;
  }

  .mainLandmarkContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .containerCardBox:hover {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    justify-items: center;
    border: 2px solid #ee8c38;
    border-radius: 15px;
    width: 37vw;
    height: 37vw;
    margin: 10px 10px 10px 10px;
    background-color: #fef9f4;
  }

  .modalContainerTable {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
    padding: 15px;
    background-color: white;
    height: 100vh;
    overflow-y: auto;
  }

  .containerButtonResponsive {
    display: flex;
    flex-direction: row;
    margin: 20px 0 20px 0;
  }

  .containerTitleResponsive {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: #515151;
    padding: 10px 0 20px 0;
  }

  .informationTableResponsive {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 0 10px 0;
    border-bottom: solid 1px #515151;
    margin: 0 0 10px 0;
  }

  .containerRowTimeBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100% !important;
  }

  .containerInputTableResponsive {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .containerSubMainTableResponsive {
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  .containerRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0 0 0;
  }

  .titleTableGrowMonitoring {
    font-weight: bold !important;
    font-family: 'Montserrat', sans-serif;
    margin: 0 5px 0 0;
  }

  .pendingStatus {
    border-color: rgb(142, 8, 8) !important;
    background-color: #fdf8f8 !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }

  .completedStatus {
    border-color: rgb(24, 130, 24) !important;
    background-color: #fafef8 !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }

  .titleModalTable {
    display: flex;
    flex-direction: row;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: bold;
    padding: 0 0 10px 0;
  }

  .containerMainExpandedResponsive {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .containerExpandedButton {
    display: flex;
    flex-direction: row;
    width: 44%;
    margin: 10px;
  }

  .titleExpanded {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: bold;
    padding: 0 0 0 20px;
  }
}
