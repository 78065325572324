/* Modal */
.modal {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-items: center;
  overflow-y: auto;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 90vw;
  height: fit-content;
}

.header {
  display: flex;
  justify-content: flex-end;
  margin: 8px;
  color: #68b3e0;
}

.headerTitle {
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  font-size: 18px !important;
  padding: 15px 0;
  border-bottom: 1px solid#f47105;
  margin-bottom: 15px;
}

.rowHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 20px 0;
}

.rowHeaderSubtitle {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: auto;
}

.containerRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.mapBox {
  display: flex;
  justify-content: center;
  width: 30%;
  height: auto;
}

.mapBorder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  border-style: solid;
  border-color: #68b3e0;
  border-width: 1px;

  height: 600px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.actionBtns {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin: 1rem 0;
}

/* Groups */

.containerGroup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 70%;
  gap: 1.5rem;
}

.scrollContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 1.5rem;
  max-height: 552px;
}

.groupBox {
  border: 1px solid #e2e2e2;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  min-height: 100px;
}

.groupBoxRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.middleGroupRadio {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.timeGroup,
.nameGroup,
.capacityGroup,
.choosableGroup {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: start;
  justify-content: center;
}

.middleGroup {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.timeGroup,
.capacityGroup {
  width: 50%;
  height: 100%;
}

.nameGroup {
  color: white;
  font-size: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  width: 200px;
}

.nameGroupText {
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  max-width: 200px;
}

.timeGroupDay {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.capacityGroup {
  align-items: start;
}

.capacityRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  gap: 1rem;
}

.choosableGroup {
  width: 10%;
}

.choosableCheck[disabled]::before {
  background: #4ca4db;
}

@media (max-width: 1300px) {
  .groupBoxRow {
    flex-direction: column;
  }
  .nameGroup {
    width: 100%;
    border-radius: 10px 10px 0 0px;
  }
  .middleGrouRadio {
    width: 100%;
  }
  .middleGroup {
    width: 90%;
    flex-direction: column;
  }
  .timeGroup,
  .capacityGroup {
    width: 100%;
  }
  .timeGroup {
    border-bottom: 1px solid #e2e2e2;
  }
  .containerRow {
    flex-direction: column;
  }
  .mapBorder {
    height: 264px;
  }

  .mapBox,
  .containerGroup {
    width: 100%;
  }
  .scrollContainer {
    overflow-y: inherit;
    max-height: none;
  }
  .nameGroupText {
    max-width: 70vw;
  }
}
@media (max-width: 999px) {
  .modal {
    height: calc(100vh - 104px);
    overflow: hidden;
  }
  .modalContainer {
    width: 100vw;
    margin: 0;
    border-radius: 0;
    overflow-y: auto;
    height: 100%;
  }

  .middleGroup {
    width: 80%;
    flex-direction: column;
  }
  .nameGroupText {
    font-size: 24px;
  }
}
