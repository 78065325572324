.mobileNav {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1770px) {
  .generalContainer {
    width: auto !important;
  }
}

.titleStyle {
  margin: 0;
  padding: 1rem 0;
}

.generalContainer {
  font-family: 'Poppins', sans-serif !important;
}

.chartContainer {
  margin: 0 auto;
  font-family: 'Poppins', sans-serif !important;
}

.buttonContainer {
  display: flex;
  width: 100%;
}

.GridStyle {
  justify-content: space-between;
  margin-top: 1%;
}

.percentileContainer {
  display: flex;
  flex-direction: row;
  border: 1px solid #67b2df;
  border-radius: 5px;
  width: fit-content;
  margin: 20px;
}

.percentileContainer p {
  font-family: 'Poppins', sans-serif;
  color: #375980;
  font-weight: bold;
  font-size: 15px;
  padding: 0 15px 0 15px;
}

.cardContent {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tabContainer {
  position: relative;
  margin-top: 58px;
  padding: 0 !important;
  border-top-left-radius: 10px;
}

.tabList {
  position: absolute;
  top: -47px;
  left: 0;
  z-index: 1;
}

div:has(> .tabItem) {
  background: #fff !important;
}

.tabContainerItem {
  overflow: hidden;
  padding: 0 1rem;
  border: 1px solid #b5b5b5;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.tabItem {
  width: auto !important;
  background: #eae9ee69 !important;
  color: #e6e6e657 !important;
  flex: none !important;
  border: 1px solid #b5b5b5 !important;
}

.tabItem span {
  padding: 0 2rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #a3a3a3;
}

.tabItem:first-of-type {
  border-top-left-radius: 10px !important;
  border-right: 0 !important;
}
.tabItem:last-of-type {
  border-top-right-radius: 10px !important;
  border-left: 0 !important;
}

.tabItemActive {
  background-color: #ffffff !important;
  border-bottom: 1px solid white !important;
}

.tabItemActive span {
  font-weight: bold;
  text-align: center;
  color: #000 !important;
}

.omsLabel {
  display: flex;
  justify-content: space-between;
}

.loadingBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #b5b5b5;
}

.spinner {
  flex-grow: 0 !important;
  height: min-content !important;
}

@media (max-width: 320px) {
  .tabLabel {
    font-size: 12px;
  }
}

.displayNone {
  display: none;
}

.gridContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0 4rem 0;
  padding: 0 1rem;
  border-bottom: 3px solid #f18b24;
}

.chartTitle {
  font-weight: bolder !important;
  color: #0063a6;
}

.ageSelector {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.gridItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* resolucion mobil */
@media (max-width: 599px) {
  .tabContainerItem,
  .tabItem {
    margin-top: 0;
    border: 0;
  }

  .mobileTabTitle {
    font-weight: bold !important;
    margin: 1rem 0;
    font-size: 1.2rem;
  }

  .displayNoneMobile {
    display: none;
  }

  .evolutionControl {
    border: none;
  }

  .generalContainer {
    overflow: auto;
    width: auto;
    margin: 0;
    font-family: 'Poppins', sans-serif !important;
    height: auto !important;
  }

  .headerContainer {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .chartTitle {
    font-size: 1rem !important;
    margin-bottom: 0.5rem;
  }

  .ageSelector {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    align-items: flex-start;
  }

  .titleStyle {
    margin: 0;
    padding: 0;
    font-weight: 400;
  }

  .gridContainer {
    display: flex;
    flex-direction: row;
  }

  .gridItem {
    padding: 1rem 0;
    font-size: small;
  }
}
