.pageContainer {
  background: #ffffff;
  margin: 25px;
  box-shadow: -1px -5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px;
}

.captionContainer {
  background: #ffffff;
  margin: 25px;
  box-shadow: -1px -5px 20px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 0;
}

.roleContainer {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.roleContainer img {
  margin-right: 15px;
}

.legalContainer {
  /*border: solid 1px #dcdcdc;*/
  background-color: rgb(248, 248, 249);
  padding: 20px 10px 0 10px;
}

.circleButton {
  margin-top: 5px;
  margin-bottom: -40px;
}

.legalContainer p {
  color: #1b1c1d;
  font-size: 15px;
  /*font-family: monospace;*/
}

.containerRow{
display: flex;
}

.formControl {
  padding-bottom: 5px;
}

.leftBar {
  border-left: 1px solid #2C80BA;
  padding-left: 50px;
}

@media (max-width: 1500px) {
  .roleContainer {
    width: 80%;
    flex-wrap: wrap;
    justify-content: left;
  }
  .role {
    margin: 10px 30px 10px 0;
  }
}

@media (max-width: 599px) {
  .pageContainer {
    margin: 10px 10px 100px 10px;
  }
  .roleContainer {
    margin: 10px;
  }
  .captionContainer {
    margin: 10px;
  }
  .circleButton {
    margin: 5px 5px 5px 0;
    display: flex;
    justify-content: flex-end;
  }
  .containerRow{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .leftBar {
    padding: 20px 0 0 0;
    border-left: 0 solid #2C80BA;
  }
}

