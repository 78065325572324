.card {
  border-radius: 8px !important;
  border: 1px solid #68b3e0;
  background: #fff;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.15);
}

.cardHover {
  border-radius: 8px!important;
  border: 1px solid #f18b24;
  transition: border-bottom-color 0.3s ease, border-top-color 0.3s ease, border-left-color 0.3s ease,
    border-right-color 0.3s ease, background-color 0.3s ease;
  background: rgba(243, 220, 196, 0.12);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.15);
}
.cardContent {
  display: flex;
  width: 208px;
  height: 240px;
  padding: 25px 19px 32px 19px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
}
.divider {
  height: 4px;
  width: 50%;
  background-color: #f18b24;
  border: none;
  margin-left: 0;
}

.icon {
  display: flex;
  width: 208px;
  height: 240px;
  padding: 25px 19px 32px 19px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.title {
  color: #515151;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat, sans-serif;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.titleContent {
  display: flex;
  padding-left: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 13px;
  align-self: stretch;
}

@media (max-width: 598px) {
  .cardContent {
    width: 130px;
    height: 167px;
    padding: 0;
  }

  .icon {
    width: 100%;
    height: 100%;
    padding: 0;
  }
.title{
    font-size: 18px!important;
    font-weight: 600;

    margin: 0;
    padding: 0;
}
  .titleContent {
    gap: 0;
  }
}