.toAsc {
  transform: rotate(180deg);
}

.toDesc {
  transform: rotate(0deg);
}

.iconBlock {
  margin: auto;
  width: 10%;
  padding: 20px 0;
  float: right;
}

.inputSearcher {
  width: 100%;
  height: 100%;
}

.containerBox {
  width: 100%;
  padding-top: 15px;
}

.inputBox {
  width: 99%;
}

.filterListIcon {
  margin: auto;
  width: 70%;
}

.addNewConversationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.addNewConversationFab {
  background-color: #f17d32 !important;
  color: #ffffff !important;
  box-shadow: none !important;
  border-radius: 100px !important;
}
