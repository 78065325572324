.modalView {
    display: flex; 
    flex-direction: column;
    width: 700px;
    background-color: #fff;
    padding: 1.5rem;
}

.label,
.labelTitle {
    margin: 0;
}

.labelTitle {
  font-weight: bold;
  color: #68b3e0;
}

.column{ 
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rowContained {
    display: flex;
    justify-content: center;
    align-items: center;
}

.titleLabel {
  font-weight: bold;
  margin: 0;
}

.label {
  font-family: 'Poppins', sans-serif;
}