.messageContainer {
  padding: 1rem;
  background-color: var(--color-white);
}

.messageContent {
  background-color: var(--color-messages);
  max-width: 95%;
  padding: 0.8rem;
  font-size: 1.1rem;
}

.messageContentSent {
  border-radius: 1rem 1rem 0 1rem;
  background-color: #f0f7fc;
}

.messageContentReceived {
  border-radius: 1rem 1rem 1rem 0;
  background-color: #fbe6da;
}

.messageContentReceived2 {
  border-radius: 1rem 1rem 1rem 0rem;
  background-color: #fbe6da;
  padding: 10px;
}

.messageLink {
  color: #f18b24;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}

.divMessagesScroll {
  display: flex;
  flex-direction: column;
  min-height: 40vh;
  max-height: 40vh;
  overflow-y: auto;
  scroll-behavior: smooth;
  background-color: var(--color-white);
}

.divMessagesScrollEmpty {
  align-items: center;
}

.author {
  font-size: 0.9rem;
  margin-bottom: 0;
  color: var(--color-dark-blue);
}

.messageFileElement {
  color: var(--color-orange);
}

.messageFileElementLink,
.messageFileElementLink:visited,
.messageFileElementLink:hover,
.messageFileElementLink:active {
  color: var(--color-light-blue);
  cursor: pointer;
}

.messageImg {
  width: 150px;
  height: 150px;
  object-fit: cover;
  cursor: pointer;
}

.messageFilesContainer {
  column-gap: 1rem;
}

@media (max-width: 599px) {
  .messageContent {
    max-width: none;
  }
  .messageContainer {
    padding: 1rem 0;
  }
  .divMessagesScroll {
    padding-bottom: 20px;
    /* max-height: none; */
  }
}
