.boxRecurrence {
  display: flex;
  margin-bottom: 20px;
}

.boxRecurrence > p {
  font-family: 'Poppins', sans-serif !important;
  font-size: 18px !important;
}

.containerConfig {
  height: 500px;
}

/* Needed for ConfigResource */

.cardMain {
  box-shadow: none !important;
  width: 100%;
}

.cardContent {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  padding: 0;
  gap: 2rem;
}

.titleConfig {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  padding-bottom: 0.5rem;
  margin: 0;
}

.divider {
  background: #68b3e0;
  margin-top: 0.5rem;
  height: 4px;
}

.select {
  background: #f5f2f2;
}

.boxContainer {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.boxSelect {
  margin-top: 30px;
}

.alertError {
  color: red;
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
}

.chipList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 32px;
  gap: 0.75rem;
}

.chip {
  margin: 1.5rem;
}
.chip:nth-child(even) {
  background: #0163a5;
}
.chip:nth-child(odd) {
  background: #68b4e1;
}

.chipText {
  font-family: 'Poppins', sans-serif;
  color: #fff;
  font-weight: bold;
}

@media (max-width: 599px) {
  .titleConfig {
    font-size: 0.9rem;
  }
}
