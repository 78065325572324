.notification {
  row-gap: 0.5rem;
  padding: 1rem;
  border: 1px solid var(--color-gray);
  min-width: 250px;
}

.inputSelect {
  min-width: 100%;
}

.deleteButton {
  padding-bottom: 10px;
}

.notificationTitle {
  display: inline-block;
  padding: 5px 25px 5px 0;
}

.container {
  margin: 5px;
}

.addNewDate {
  width: 0.5rem;
}

.datePicker {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row {
  display: flex;
  align-items: center;
  padding-bottom: 18px;
}

.containerNotification{
  border-bottom: 1px solid #d1d1d1;
}

.row:last-child {
  border-bottom: none;
}

.notificationTitle2 {
  display: inline-block;
  width: 82px;
}

.notifyAt {
  display: inline;
  margin-left: 34px;
  margin-right: 36px;
}

.notifyAtDisabled {
  color: #9d9d9d;
}

.datePicker2 {
  width: 280px;
  margin-top: 10px !important;
  vertical-align: middle;
}

.addDate {
  cursor: pointer;
  vertical-align: middle;
  margin-left: 15px;
}

.chipBox {
  width: 800px;
}

.chip {
  margin: 3px 5px;
}
