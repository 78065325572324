.button {
  font-size: 14px !important;
  text-transform: none !important;
  font-family: 'Poppins', sans-serif !important;
  padding: 8px 22px !important;
  border-radius: 4px !important;
  background-color: #0a4264 !important;
  color: #ffffff !important;
}

.button2 {
  font-size: 14px !important;
  text-transform: none !important;
  font-family: 'Poppins', sans-serif !important;
  padding: 0 !important;
  border-radius: 4px !important;
  background-color: #0a4264 !important;
  color: #ffffff !important;
}

.newPrimary {
  background-color: #68b3e0 !important;
  font-family: 'Poppins', sans-serif !important;
  color: #ffffff !important;
  font-size: 16px !important;
  border-radius: 8px !important;
}

.newPrimaryRemove {
  background-color: #e04e52 !important;
  font-family: 'Poppins', sans-serif !important;
  color: #ffffff !important;
}

.newSecondary {
  background-color: #ffffff !important;
  border: 1px solid #919191 !important;
  color: #919191 !important;
  font-family: 'Poppins', sans-serif !important;
}

.transparent {
  background-color: #ffffff !important;
  border: none;
  font-family: 'Poppins', sans-serif !important;
}

.transparentOrange {
  background-color: #ffffff !important;
  border: none;
  color: #f88c24 !important;
  padding: 0 !important;
  font-family: 'Poppins', sans-serif !important;
}

.transparentOutlinedOrange {
  background-color: #ffffff !important;
  border: 2px !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-color: #f88c24 !important;
  color: #f88c24 !important;
  padding: 6px 20px !important;
  font-family: 'Poppins', sans-serif !important;
}

.newEvent {
  background-color: #68b3e0 !important;
  color: #ffffff !important;
  border-radius: 10px !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.newEventOrange {
  background-color: #f88c24 !important;
  color: #ffffff !important;
  border-radius: 10px !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.disabelEvent {
  display: none !important;
}

.newOrangeButton {
  background-color: #f88c24 !important;
  color: #ffffff !important;
  padding: 10px !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Montserrat', sans-serif !important;
  margin-right: 10px !important;
}

.calendarFilter {
  border: 1px solid #919191 !important;
  background-color: #eeecec !important;
  border-radius: 25px !important;
}

.whiteAndBlue {
  background-color: #ffffff !important;
  color: #68b3e0 !important;
  border: 1px solid #68b3e0 !important;
  border-radius: 8px !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: normal !important;
}

.newPrimaryResponsive {
  background-color: #68b3e0 !important;
  font-family: 'Poppins', sans-serif !important;
}

.addGroup {
  display: flex;
  flex-direction: row;
  justify-content: start !important;
  background-color: #5dd91a !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  font-size: 20px !important;
  border-radius: 15px !important;
  color: #ffffff !important;
  width: 250px !important;
}

.addCityHall {
  display: flex;
  flex-direction: row;
  justify-content: start !important;
  background-color: #5dd91a !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  font-size: 18px !important;
  border-radius: 15px !important;
  color: #ffffff !important;
  width: 250px !important;
}

.removeCityHall {
  display: flex;
  flex-direction: row;
  justify-content: start !important;
  background-color: #ec0b0b !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  font-size: 18px !important;
  border-radius: 15px !important;
  color: #ffffff !important;
  width: 250px !important;
}

.backToCalenar {
  display: flex;
  flex-direction: row;
  justify-content: start !important;
  background-color: #68b3e0 !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  font-size: 20px !important;
  border-radius: 15px !important;
  color: #ffffff !important;
  width: 250px !important;
}

.addParticipant {
  background-color: #5dd91a !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  width: 100%;
  height: 3rem !important;
}

.reinviteParticipant {
  background-color: #68b3e0 !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  width: 100%;
  text-align: left !important;
  height: 3rem !important;
  padding-left: 1rem !important;
}

.reinviteParticipantDisabled {
  background-color: #68b3e0 !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  width: 100%;
  text-align: left !important;
  height: 3rem !important;
  padding-left: 1rem !important;
}

.removeGroup {
  background-color: #ec0b0b !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  /* margin-bottom: -5% !important; */
  width: 100%;
  text-align: left !important;
  height: 3rem !important;
}

.addCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  background-color: #5dd91a !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: bold !important;
  font-size: 15px !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  width: 260px;
}
.removeCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  background-color: #ec0b0b !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: bold !important;
  font-size: 15px !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  width: 260px;
}

.disabled {
  background-color: silver !important;
  cursor: not-allowed;
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  border-radius: 10px;
  color: #ffffff !important;
  width: 250px;
}

.removeField {
  background-color: transparent !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  padding: 0 !important;
}

.borderOrangeTextBlack {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  background-color: #ffffff !important;
  border: 2px !important;
  border-radius: 10px !important;
  border-style: solid !important;
  border-color: #f88c24 !important;
  color: #515151 !important;
  padding: 6px 20px !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: bold;
  font-size: 18px !important;
  width: 100%;
}

.borderBlueTextBlue {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  background-color: #ffffff !important;
  border: 2px !important;
  border-radius: 10px !important;
  border-style: solid !important;
  border-color: #68b3e0 !important;
  color: #68b3e0 !important;
  padding: 6px 10px !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: bold !important;
  font-size: 15px !important;
  width: 100%;
}

.borderGrayActivePlan {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  background-color: #ffffff !important;
  border: 2px solid #dbdada !important;
  border-radius: 10px !important;
  color: black !important;
  padding: 6px 10px !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: bold !important;
  font-size: 15px !important;
  width: 100%;
}

@media (max-width: 1024px) {
  .addParticipant {
    width: auto;
  }
  .removeGroup {
    width: auto;
  }
  .reinviteParticipant {
    width: auto;
  }
}
@media (max-width: 599px) {
  .addParticipant {
    width: 100%;
  }
  .removeGroup {
    width: 100%;
  }
  .reinviteParticipant {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .newPrimaryResponsive {
    width: 33%;
  }
  .newPrimary {
    font-size: 14px !important;
  }
}

@media (min-width: 1024px) {
  .newPrimaryResponsive {
    width: 25%;
  }
}

@media (min-width: 1440px) {
  .newPrimaryResponsive {
    width: 12%;
  }
}
