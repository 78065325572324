.containerList {
  height: 510px;
  overflow-y: auto;
  cursor: pointer;
}

.icon {
  height: 35px;
}

.divider{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    background-color: #AFD7EF !important;
    width: 100% !important
}

.listItemText{
  font-family: 'Poppins', sans-serif !important;
  text-transform: capitalize;
}

.image{
  width: 100px;
  margin-right: 10px;
}

.title{
  color:#7fb6de
}

.titleEditor {
  padding: 0 10px;
  font-weight: bold !important;

  span:last-child{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


.notificationContainer{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #68B3E0;
  padding:10px;
  cursor: pointer;
  background: white;
  width: 100%;
  padding: .90rem 1rem!important;
  gap: 0.5rem;
  height: 52px;
}


.notificationContainer:last-child {
  margin-bottom: 0!important;
}

.listItemText > span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}