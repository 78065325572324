.messageContainer {
  padding: 1rem;
  background-color: var(--color-white);
  width: 100%;
}

.messageContent {
  background-color: var(--color-messages);
  max-width: 100%;
  padding: 0.8rem;
  font-size: 1.1rem;
}

.messageContentSent {
  border-radius: 1rem 0 1rem 1rem;
  background-color: #FEF1E0;
}

.messageContentReceived {
  border-radius: 0 1rem 1rem 1rem;
  background-color: #F0F7FD;
}

.divMessagesScroll {
  display: flex;
  flex-direction: column;
  min-height: inherit;
  max-height: inherit;
  overflow-y: auto;
  scroll-behavior: smooth;
  background-color: var(--color-white);
}

.divMessagesScrollEmpty {
  justify-content: center;
  align-items: center;
}

.author {
  font-size: 0.9rem;
  margin-bottom: 0;
  color: var(--color-dark-blue);
}

.messageFileElement {
  color: var(--color-orange);
}

.messageFileElementLink,
.messageFileElementLink:visited,
.messageFileElementLink:hover,
.messageFileElementLink:active {
  color: var(--color-light-blue);
  cursor: pointer;
}

.messageImg {
  width: 150px;
  height: 150px;
  object-fit: cover;
  cursor: pointer;
}

.messageFilesContainer {
  column-gap: 1rem;
}

.textStyle{
  word-wrap: break-word;
}
.avatar{
  margin : 0 1rem 0 1rem;
}


@media (max-width: 599px){
  .messageContentReceived {
    border-radius: 0 1rem 1rem 1rem;

    background-color: #F0F7FD;
  }

  .messageContentSent {
    border-radius: 1rem 0 1rem 1rem;
    background-color: #FEF1E0;
  }

}
