.autocompleteStyle {
  width: inherit;
  max-width: 263px;
  font-family: 'Poppins', sans-serif !important;
}

.notificationContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  padding: 5px 0px;
}

.formSpacing {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  column-gap: 1rem;
  width: 300px;
}

.buttonsSpacingBetween {
  display: flex;
  justify-content: space-between;
}

.inlineCheckbox {
  display: flex;
  flex-direction: row;
  float: left;
}

.addButton {
  height: fit-content;
  width: 10rem;
}

.errorMessageTags {
  font-size: 1rem;
  width: 70%;
}

.errorMessageForm {
  font-size: 1rem;
  width: 90%;
}

.form {
  width: inherit;
}

.fieldsContainer {
  row-gap: 1rem;
  column-gap: 1rem;
}
