.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    font-weight: bold;
}

.containerWrapper {
    border: 2px solid #68B3E0;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    padding: 14px 22px;
}

.underLainedTitle {
    border-bottom: 2px solid #F18B24;
    font-size: 20px;
}

.containerItem {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    border-right: 2px solid #68B3E0;
}
.containerItem:last-of-type {
    border: none;
    padding-right: 0;
}
.containerItem:first-of-type {
    padding-left: 0;
}

.numberStat {
    font-size: 66px;
    color: #68B3E0;
    font-weight: bolder;
}

.showmore {
    font-size: 14px;
    cursor: pointer;
}
.lastUpdate{
    font-size: 14px;

}
.hiddenshowmore {
   height: 21px;
}

@media (max-width: 599px){
    .container {
        padding: 0;
    }
    .containerWrapper {
        width: 100%;
        border: none;
        padding: 0;
        flex-wrap: wrap;
        gap: 16px;
    }
    .containerItem {
        padding: 16px!important;
        width: calc(50% - 8px);
        border: 2px solid #68B3E0!important;
        border-radius: 8px;

    }
    .underLainedTitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 62px;
        border: 0;
    }
    .underLainedTitle::after {
        content: '';
        width: 60%;
        height: 2px;
        background: #F18B24;
        display: flex;
        margin: 0 auto;
    }
    .showmore {
        height: 42px;
        display:flex;
        flex-direction: column;
        justify-content: center;
    }
   
}