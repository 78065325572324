.headerContainer {
  background-color: #375980 !important;
}

.messengerContainer {
  display: flex;
  justify-content: start;
  margin: 0 1rem;
  gap:1rem;
}

.TabsContainer {
  display: flex;
  column-gap: 1rem;
  flex-direction: column;
}

.messagesContainer {
  min-height: 40vh;
  max-height: 45vh;
}

.newConversationBox {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end; /* Alinea horizontalmente al final */
}


.searcherContainer {
  column-gap: 1rem;
}

.modalNewConversation {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin: 10px;
}

.labelCreateOrSelectConversation {
  text-align: center;
  margin: 0;
  max-width: 200px;
  font-weight: bold;
}
.imgCreateOrSelectConversation{
  width: 75px;
  height: 75px;
}
.orangeUnderscore {
  width: 75px;
  height: 2px;
  background: #f37e31;
}

.orangeUnderscorePulse {
  width: 75px;
  height: 2px;
  animation: pulse 1.5s infinite;
}

.conversationWidget {
  background-color: #ffffff !important;
  padding: 20px !important;
  max-width: 528px;
  min-width: 40%;
  border-radius: 10px;
  box-shadow: 0 5px 5px #d8d8d8;
}

.messengerWidget {
  padding: 0!important;
  margin: 0;
  width: 100%;
  position: relative;
}

.senderMobile {
  position: absolute;
  bottom: 104px;
  left: 0;
  right: 0;
  background: white;
}

.emptyConversation {
  display: flex;
  flex-direction: column;
  min-height: 200px;
  gap: 0.5rem;
  background-color: transparent;
  border: 2px solid #d8d8d8;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.blueLine {
  padding-top: 20px;
  margin-bottom: 20px;
  border-bottom: 3px solid var(--color-light-blue);
}
.chatList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
  max-height: 35vh;
  padding: 15px 0;
}
.chatList::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.chatList::-webkit-scrollbar-thumb {
  background: #ff0000;
}

.switchButtonContainer {
  display: flex;
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
  /* font-weight: bold; */
}

.displayNone{
  display: none
}

@media (max-width: 900px) {
  .messengerContainer {
    flex-direction: column;
    margin: 0.5rem;
  }
  .conversationWidget {
    width: auto !important;
    max-width: none;
    margin-bottom: 20px;
  }
  
  .emptyConversation {
    width: auto !important;
    height: 70px;
    margin-right: 0 !important;
  }
  /* .labelCreateOrSelectConversation {
    font-size: 1.1rem !important;
  } */
}

/*resolucion movil*/
@media (max-width: 599px) {

  .conversationWidget {
    background-color: white !important;
    padding: 0 !important;
    margin: 0;
    min-width: 0%;
    width: 30%;
    box-shadow: none;
  }

  .messengerWidget {
    padding: 0 1rem!important;
  }

  .chatList {
    padding: 0 1rem;
    max-height: none;
  }
  
  .emptyConversation{
    display: none;
  }

  .backButtonContaienr{
    background-color: white;
    margin: 0 0 0 10px;
  }

  .messengerContainer {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    padding: 0 !important;
    background-color: white;
  }

  .searcherContainer {
    column-gap: 0;
    margin: 10px;
  }

}

@keyframes pulse {
  0%, 100% {
    background-color: #f37e31;
  }
  50% {
    background-color: transparent;
  }
}