.input {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 1em 1em 0 1em;
  max-width: 300px;
  width: calc(100% - 2em);
  vertical-align: top;
  -webkit-appearance: none;
}

.input__field {
  position: relative;
  display: block;
  float: right;
  padding: 0.8em;
  width: 60%;
  border: none;
  border-radius: 0;
  background: #f0f0f0;
  color: #aaa;
  font-weight: 400;
  font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-appearance: none; /* for box shadows to show on iOS */
}

.input__field:focus {
  outline: none;
}

.input__label {
  display: inline-block;
  float: right;
  padding: 0 1em;
  width: 40%;
  color: #696969;
  font-weight: bold;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input__label-content {
  position: relative;
  display: block;
  padding: 1.6em 0;
  width: 100%;
}

.graphic {
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
}

.icon {
  color: #696969;
  font-size: 150%;
}

/* Individual styles */
/* Fumi */
.input--fumi {
  background: #fafafa;
  overflow: hidden;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 12px;
}

.input--fumi::after {
  content: '';
  width: 1px;
  position: absolute;
  top: 0.5em;
  bottom: 0.5em;
  left: 2.5em;
  background: #ccc;
  z-index: 100;
}

.input__field--fumi {
  background: transparent;
  padding: 1.5em 1em 0.5em 3em;
  font-size: 20px;
  width: 100%;
  color: #4c4e50;
  box-shadow: none;
  box-sizing: border-box;
}

.input__label--fumi {
  position: absolute;
  width: 100%;
  text-align: left;
  padding-left: 4em;
  pointer-events: none;
}

.icon--fumi {
  width: 1.5em !important;
  font-size: 20px !important;
  position: absolute;
  top: 0.2em;
  left: 0;
  padding: 0.9em 0 0 0.3em;
}

.input__label-content--fumi {
  padding: 1.7em 0;
  display: inline-block;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.input__label-content--fumi span {
  display: inline-block;
}

.input__field--fumi:valid + .input__label--fumi .input__label-content--fumi,
.input--filled .input__label-content--fumi {
  -webkit-animation: anim-fumi-1 0.3s forwards;
  animation: anim-fumi-1 0.3s forwards;
  color: #0c78c2;
}

.input__field--fumi:focus + .input__label--fumi .input__label-content--fumi,
.input--filled .input__label-content--fumi {
  -webkit-animation: anim-fumi-1 0.3s forwards;
  animation: anim-fumi-1 0.3s forwards;
  color: #0c78c2;
}

@-webkit-keyframes anim-fumi-1 {
  50% {
    -webkit-transform: translate3d(0, 3em, 0);
    transform: translate3d(0, 3em, 0);
  }
  51% {
    -webkit-transform: translate3d(0, -3em, 0) scale3d(0.85, 0.85, 1);
    transform: translate3d(0, -3em, 0) scale3d(0.85, 0.85, 1);
  }
  100% {
    color: #0c78c2;
    -webkit-transform: translate3d(0, -1.1em, 0) scale3d(0.85, 0.85, 1);
    transform: translate3d(0, -1.1em, 0) scale3d(0.85, 0.85, 1);
  }
}

@keyframes anim-fumi-1 {
  50% {
    -webkit-transform: translate3d(0, 3em, 0);
    transform: translate3d(0, 3em, 0);
  }
  51% {
    -webkit-transform: translate3d(0, -3em, 0) scale3d(0.85, 0.85, 1);
    transform: translate3d(0, -3em, 0) scale3d(0.85, 0.85, 1);
  }
  100% {
    -webkit-transform: translate3d(0, -1.1em, 0) scale3d(0.85, 0.85, 1);
    transform: translate3d(0, -1.1em, 0) scale3d(0.85, 0.85, 1);
    color: #0c78c2;
  }
}

.input__field--fumi:valid + .input__label--fumi .icon--fumi,
.input--filled .icon--fumi {
  -webkit-animation: anim-fumi-2 0.3s forwards;
  animation: anim-fumi-2 0.3s forwards;
  color: #0c78c2;
}

.input__field--fumi:focus + .input__label--fumi .icon--fumi,
.input--filled .icon--fumi {
  -webkit-animation: anim-fumi-2 0.3s forwards;
  animation: anim-fumi-2 0.3s forwards;
  color: #0c78c2;
}

@-webkit-keyframes anim-fumi-2 {
  50% {
    opacity: 1;
    -webkit-transform: translate3d(0, -3em, 0);
    transform: translate3d(0, -3em, 0);
  }
  50.25% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3em, 0);
    transform: translate3d(0, -3em, 0);
  }
  50.75% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3em, 0);
    transform: translate3d(0, 3em, 0);
  }
  51% {
    opacity: 1;
    -webkit-transform: translate3d(0, 3em, 0);
    transform: translate3d(0, 3em, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    color: #0c78c2;
  }
}

@keyframes anim-fumi-2 {
  50% {
    opacity: 1;
    -webkit-transform: translate3d(0, -3em, 0);
    transform: translate3d(0, -3em, 0);
  }
  50.25% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3em, 0);
    transform: translate3d(0, -3em, 0);
  }
  50.75% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3em, 0);
    transform: translate3d(0, 3em, 0);
  }
  51% {
    opacity: 1;
    -webkit-transform: translate3d(0, 3em, 0);
    transform: translate3d(0, 3em, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    color: #0c78c2;
  }
}
