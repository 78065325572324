.boxRecurrence {
  display: flex;
  margin-bottom: 20px;
}

.boxRecurrence > p {
  font-family: 'Poppins', sans-serif !important;
  font-size: 18px !important;
}

.cardContainer {
  box-shadow: none !important;
  width: 100%;
}

.containerConfig {
  height: 500px;
}
