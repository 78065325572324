.container {
  margin-top: 80px;
}
.title {
  font-family: 'Poppins', sans-serif !important;
  font-size: 18px !important;
  color: #68b3e0;
  font-weight: bold !important;
}
.backButton {
  margin-bottom: 10px;
  display: inline-block;
}

.selectButton {
  margin-bottom: 10px;
  margin-left: 10px;
  display: inline-block;
}

.selectBox {
  margin-top: 10px;
  display: flex;
  border: 1px solid #68b3e0;
  border-radius: 4px;
  padding: 20px;
}

.selectCheckBoxContent {
  display: flex;
  flex-wrap: wrap;
  width: auto;
  align-items: center;
}

.radioButtonCustom {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.collapse {
  cursor: pointer;
  vertical-align: middle;
}

.nameLabel {
  margin-bottom: 4px;
  padding-left: 6px;
  display: inline;
}

.subRow {
  background-color: #f4f6f7;
}

.letterBoxContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.letterBox {
  border: 1px solid #68b3e0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 10px;
  margin-right: 10px;
  height: 30px;
  cursor: pointer;
}

.letterBoxClicked {
  background-color: #f37e31;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 10px;
  margin-right: 10px;
  height: 30px;
  cursor: pointer;
}

.letter {
  font-family: 'Poppins', sans-serif !important;
}

.letterClicked {
  font-family: 'Poppins', sans-serif !important;
  color: white !important;
}

.textSelectUser {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
}

.cardsContainer {
  margin-top: 20px;
}

.cardsContent {
  display: flex;
}

.cards {
  padding: 10px;
  margin: 10px;
  width: 350px !important;
  height: 380px !important;
}

.headerContainer {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
}

.headerContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.textName > span {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 900 !important;
  margin-bottom: 0 !important;
  padding-bottom: 2px !important;
  cursor: pointer;
}

.textDNI {
  font-family: 'Poppins', sans-serif !important;
  margin-top: 0 !important;
  margin-left: 15px !important;
  font-size: 14px !important;
}

.noPointerEvents {
  font-family: 'Poppins', sans-serif !important;
  margin-top: 0 !important;
  margin-left: 15px !important;
  font-size: 14px !important;
  visibility: hidden !important;
}

.relaters > span {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
  cursor: pointer;
}

.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.list::-webkit-scrollbar {
  display: none !important;
}

.relatersList {
  display: flex !important;
  align-items: baseline !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  width: auto !important;
  max-width: 136px;
  min-width: 136px;
  padding: 0 !important;
}

.imageRoles {
  width: 15px;
  height: 15px;
}

.divider {
  margin-top: 8px !important;
}

.cardContent {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

@media (max-width: 1533px) {
  .textDNI {
    font-size: 10px !important;
  }

  .relatersList {
    max-width: 130px;
    min-width: 130px;
  }
}

@media (max-width: 1490px) {
  .textDNI {
    font-size: 10px !important;
  }

  .relatersList {
    max-width: 129px;
    min-width: 129px;
  }
}
