.container {
  margin-top: 80px;
  margin-right: 50px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
}

.textField {
  margin-bottom: 20px;
  width: 100%;
}

.customBorder {
  border-bottom: 3px solid #68b3e0 !important;
  font-size: 300px !important;
}

.containerInput {
  display: flex;
  justify-content: space-between;
  max-width: 92%;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.test {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.invisibleCheckbox {
  visibility: hidden;
}

.formTableContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fff;
  margin: 2em;
  padding: 0 5px 0 5px !important;
}

.modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Para un fondo semi-transparente, si lo deseas */
  z-index: 999;
}

.modalBoxContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  width: 100%;
  height: 100%;
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  margin: 8px;
  margin-bottom: 30px;
}

.contentHeaderExitIcon {
  display: flex;
  justify-content: end;
}

.modalOverFlow {
  overflow: scroll;
}

.titleLabel {
  font-weight: bold;
}

.celdaSinBorde {
  border-bottom: none !important;
}

.MuiTableRow-root td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.MuiTableRow-root td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  margin: 5% 19% 10% 19%;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
}

.inputName {
  border-radius: 5px;
  width: 100%;
  height: 32px;
  border: 1px solid #999;
  font-size: 18px;
}
.inputDescription {
  width: 100%;
  height: 128px;
  border-radius: 5px;
  border: 1px solid #999;
  font-size: 18px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}
