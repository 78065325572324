.modal {
  display: flex;
  overflow: auto;
  justify-content: center;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  margin: 2rem;
  border-radius: 4px;
  padding: 10px;
}

.exitIcon {
  display: flex;
  justify-content: end;
}

.formModal {
  padding: 2rem;
  background: #fff;
}
.datePickerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0;
}

.datePicker {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1024px) {
  .datePickerContainer {
    flex-direction: column;
    gap: 1.5rem;
  }
  .datePicker {
    width: 100%;
    flex-direction: column;
    align-items: start;
  }
  .modal {
    width: 100%;
    height: 100%;
  }

  .modalContainer {
    width: 100%;
  }
}

@media (max-width: 599px) {
  .modalContainer {
    margin: 0;
    padding: 0;
  }
  .formModal {
    border-radius: 0;
  }
}
