.loginContainer {
  display: flex;
  justify-content: center;
}

.login {
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 80px 30px 80px;
  background-color: #ffffff;
  border-radius: 20px;
  width: 700px;
  border: 2px solid  var(--color-dark-blue);

}

.headerTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem;
}

.headerTop img {
  max-height: 30px;
}

.headerTopRight {
  display: flex;
  align-items: center;
}

.personalData {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  padding: 30px 80px 30px 80px;
  background-color: #ffffff;
  border-radius: 20px;
  width: 80%;
  height: 70vh;
  overflow: auto;
  text-align: justify;
}

.legalWarning {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  overflow: auto;
  text-align: justify;
  padding: 10px;
  background-color: #ffffff;
  padding-top: 10px;
}

.legalWarningTitle {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  background-color: #eeeded;
  padding-left: 10px;
  border-radius: 10px 10px 0px 0px;
}

.backButton {
  margin-top: 20px;
  display:flex;
  width:100%;
  align-content:center;
  justify-content:center;
}

@media (max-width: 700px) {
  .personalData {
    width: 96%;
    padding: 30px 15px 30px 15px;
  }

  .backButton {
    width: 96%;
  }
}

.confTermFields {
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-start;
  width: 100%;
}

.tableStyle1 {
  border-color: black;
  border-style: solid;
  border-width: 2px 2px 0px 2px;
  padding: 5px;
}

.tableStyle11 {
  border-color: black;
  border-style: solid;
  border-width: 2px 2px 0px 0px;
  padding: 5px;
}

.tableStyle2 {
  border-color: black;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  padding: 5px;
}

.tableStyle21 {
  border-color: black;
  border-style: solid;
  border-width: 2px 2px 2px 0px;
  padding: 5px;
}

.noneButton {
  display: none;
}

@media (max-width: 700px) {
  .login {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 40px 30px 40px;
    background-color: #ffffff;
    border-radius: 20px;
    width: 80%;
    border: 2px solid  var(--color-dark-blue);
  }

  .confTermFields {
    width: 96%;
  }
}
