.pageContainer {
  position: relative;
  margin: 0 25px 25px 25px;
}

.container {
  margin-top: 46px !important;
  background: #ffffff;
  box-shadow: -1px -5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 8px;
  padding: 15px;
}

.tabs {
  position: absolute;
  padding: 0 !important;
}

.tab {
  font-size: 16px !important;
  font-style: normal !important;
  line-height: normal !important;
  font-family: 'Montserrat', sans-serif !important;
  color: #68b3e0 !important;
  background: #e4e4e4 !important;
  border: 1px solid #68b3e0 !important;
  border-radius: 10px 10px 0px 0px !important;
  padding: 0 2rem !important;
}

.tabActive {
  border-bottom: 1px solid #fff !important;
  font-weight: bold !important;
  background: #fff !important;
  color: #e09468 !important;
}

@media (max-width: 600px) {
  .pageContainer {
    margin: 10px;
  }
}
