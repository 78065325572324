.title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  border-bottom-color: #ee8c38;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  padding: 0 0 10px 0;
  margin: 0 0 20px 0;
}

.symtomName {
  font-family: 'OpenSans', sans-serif;
  font-size: 18px;
}
.loaderBox {
  display: flex;
  justify-content: center;
  padding: 3rem;
  width: 100%;
}

.checkBoxLabel {
  display: inline;
}

.label {
  margin: 0;
}

.select {
  width: 120px;
}

.styleCheckBox {
  width: 25px;
  height: 25px;
}

.labelContainer {
  min-width: 120px;
}

.labelText {
  font-family: 'Open Sans', sans-serif;}

.dateTimeField {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 16px;
}

.commentContainer {
  margin-bottom: 24px;
}

.buttonContainer {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
}

.inputContainer {
  display: flex;
  gap: 24px;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.inputBox {
  flex: 1;
}

.displayNone{
  display: none;
}

.autocompleteBox {
  display: flex;
}

.autocompleteStyle {
  width: 60%;
}

@media (max-width: 1024px) {
  form {
     width: 100% !important; 
  }
}

@media (max-width: 599px) {

  .autocompleteStyle {
    width: 100%;
  }

  .inputContainer {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .inputBox {
    width: 100%;
    margin-bottom: 16px;
  }

  .dateTimeField {
    width: 100%;
  }
  
  

  .labelContainer {
    margin-bottom: 8px;
  }

}

@media(max-width: 599px){
  .containerRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0 0 0;
  }

  .containerRowTime{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .dateTimeField {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  .labelContainer {
    max-width: 100px;
    margin-bottom: 0; 
  }

  .dateTimeField :global(.MuiTextField-root) {
    flex: 1; 
  }

  .dateTimeField .MuiInputAdornment-root {
    display: flex;
    align-items: center;
  }
  
  .dateTimeField .MuiInputBase-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  

  .containerTimeText{
    font-size: 16px;
    color: #555;
  }

  .containerTable{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .containerTableRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .containerTableBorder{
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }

  .checkBoxTable{
    margin: 0 10px 0 0;
  }

}

