.container {
  margin-top: 20px;
  margin-bottom: 32px;
}
.title {
  font-family: 'Poppins', sans-serif !important;
  font-size: 18px !important;
  color: #68b3e0;
  font-weight: bold !important;
}

.moveIcon {
  margin-right: 10px;
}
