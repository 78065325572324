.modalOverFlow {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    min-width: 800px;
    max-width: 1400px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }