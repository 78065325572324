.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    gap: 1rem;
}

.formControl {
    min-width: 250px;
    width: 400px;
    height: 40px!important;
    background: #f7f6f7;
}

.actionBtnRow {
    width: auto;
    margin-left: auto;
}

.actionBtnDelete {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.formHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:1rem;
}

.formTitleRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
}

.formTitleLabel {
    font-weight: bold;
}

.formTitleProf,
.formTitleCircle {
    color: white;
    display: flex;
    align-items: center;
    border-radius: 100px;
    padding: 7px 18px;
}

.formTitleProf {
    background: #76c4fd;
}

.formTitleCircle {
    background: #186cb4;
}

.actionBtns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;
}
@media (max-width: 800px) {
    .contaienr {
        flex-direction: column;
        justify-content: space-between;
    }
    .formControl {
        width: 100%;
    }
    .formHeader {
        flex-direction: column;
        align-items: start;
    }
    .actionBtnRow {
        width: 100%;
    }
    .actionBtns {
        justify-content: space-between;
        gap: 0.5rem;
    }
}