.modal {
  display: flex;
  justify-content: center;
  padding-top: 13%;
}

.modalContainer {
  display: flex;
  width: 1100px;
  height: 434px;
  padding: 23px 0 32px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  gap: 43px;
}

.modalContainerLarge {
  display: flex;
  width: 1150px;
  height: 740px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  padding: 23px 10px 32px 23px;
  gap: 43px;
}

.backButton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 64px;
}

@media (max-width: 1030px) {
  .modal {
    margin: 0;
    padding: 0;
  }
  .modalContainer {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 40px 0 40px;
    border-radius: 0 !important;
  }

  .backButton {
    display: flex;
    justify-content: center;
    margin-right: 0;
  }
}
