.containerDate {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #9d9d9d;
}
.labelDate {
  margin-right: 20px !important;
  font-family: 'Poppins', sans-serif !important;
}

.formRadioButton {
  width: 50%;
}

.ContainterRadioAndInput {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.ContainterRadioAndInputMobile {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ContainerTextField {
  width: 50%;
}

.ContainerTextFieldMobile {
  margin-top: 15px;
}

.addDetailsCrisisButton {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.saveButton {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.colorsBox {
  display: flex;
  flex-direction: row;
}

@media (max-width: 980px) {
  .colorsBox {
    flex-direction: column;
  }
}

.configButton {
  display: flex;
  margin-left: 20px;
  margin-right: -20px;
  z-index: 99;
}

.addButton {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 40px;
}

.error {
  display: flex;
  justify-content: flex-end;
  color: #f63535;
  margin-top: 10px;
}

.buttons {
  cursor: pointer;
  width: 40px;
  margin-right: 10px;
}

.radioGroupDetails {
  margin-right: 87px !important;
  margin-left: 15px;
}

.radioGroupDetailsMobile {
  margin-right: 150px;
}

.datePicker {
  margin-right: 0px !important;
  border: 1px solid #d1d1d1 !important;
  padding: 4px !important;
  border-radius: 4px !important;
}

.hourPicker {
  margin-right: 0px !important;
  border: 1px solid #d1d1d1 !important;
  padding: 4px !important;
  border-radius: 4px !important;
  font-family: 'Poppins', sans-serif !important;
}

.detailTitle {
  color: #68b3e0 !important;
  font-weight: bold !important;
  border-bottom: 3px solid #0c78c2;
}
.hourContainer {
  display: flex;
  border-bottom: 3px solid #9d9d9d;
  gap: 2em;
}
.hourContainerMobile {
  display: flex;
  flex-direction: column;
  border-bottom: 3px solid #9d9d9d;
}

.hourContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hourContentMobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.boxLevel {
  width: 40px;
  height: 30px;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif !important;
}

.required {
  color: red;
}

.icoAdd {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #68b3e0;
  border-radius: 40px;
  cursor: pointer;
}
.buttonSaveDetails {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.buttonColorsConfiguration {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
