.cardProfile{
  border: 1px solid #68B3E0 !important;
  box-shadow: 0px 4px 5px 0px #00000026 !important;
  background-color: #f7fbfd !important;
  height: 100%;
}

.images{
  width: 80px;
  height:80px;
}
.containerCard{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.divider{
  margin: 0 20px;
  height: 40px;
}

.profileText{
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #000000;
}

.card {
  height: 100%;
}