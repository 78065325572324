.row {
  border-bottom: 1px solid var(--color-gray);
}

.label {
  font-weight: bold !important;
}

.cell {
  padding-left: 30px;
}
