.backButton {
  margin-bottom: 10px;
  display: inline-block;
}

.selectButton {
  margin-bottom: 10px;
  margin-left: 10px;
  display: inline-block;
}

.selectBox {
  border: 1px solid rgba(224, 224, 224, 1);
}

.collapse {
  cursor: pointer;
  vertical-align: middle;
}

.nameLabel {
  margin-bottom: 4px;
  padding-left: 6px;
  display: inline;
}

.subRow {
  background-color: #f4f6f7;
}
