.card {
  height: 100%;
  margin-right: 8px;
}

.containerCard {
  display: flex;
  flex-direction: column;
}

.rowSpaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
