.pendingStatus {
  border-color: #CF3031 !important;
}

.completedStatus {
  border-color:  #6CE32B!important;
}

.pendingStatusText {
  border-color: #CF3031 !important;
  color: #CF3031 !important;
}

.completedStatusText {
  color: #6CE32B !important;
  border-color:  #6CE32B!important;
}

.permanentStatus {
  color: rgb(255, 128, 0) !important;
}

@media (max-width: 599px){
  .containerTableMobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }

  .title{
    display: flex;
    flex-direction: row;
    font-weight: bold;

  }



  .containerTitleMobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 10px 0;
    marging: 0 0 10px 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: black;
  }

  .containerColumn{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .containerDescription{
    display: flex;
    flex-direction: row;
    font-weight: bold;
    padding: 10px 0 10px 0;
  }


  .pendingStatusMobile {
    background-color: #fff8f8 !important;
    border-color: #CF3031 !important;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }

  .completedStatusMobile {
    background-color: #fafef8 !important;
    border-color: #6CE32B !important;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
}