.modal {
  display: flex;
  justify-content: center;
  padding-top: 4%;
}

.modalContainer {
  overflow: auto;
  width: 800px;
  height: 668px;
  flex-shrink: 0;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 57px 27px 27px 27px;
  background: #ffffff;
  margin: 25px;
}

.icon {
  cursor: pointer;
}

.datePickerContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.datePickerOnlyOne {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
}

.commentTextField {
  margin-bottom: 13px;
  width: 750px;
}

.buttonsContainer {
  display: flex;
  width: 750px;
  margin-top: 36px;
  justify-content: space-between;
}

.exitIcon {
  margin-top: -37px;
  display: flex;
  justify-content: flex-end;
}
