.header {
    margin-top: 0;
    font-weight: bold;
    font-size: 1rem;
    padding-bottom: 10px;
    border-bottom: 3px solid var(--color-light-blue);
    margin-bottom: 30px;
}

.container {
    border: 1px solid #d1d1d1;
    width: 60%;
}

.selectedContainer {
    border: 1px solid #d1d1d1;
}

.label {
    font-weight: bold;
    margin: 15px;
    vertical-align: middle;
}

.autocompleteStyle {
    width: 35%;
    margin: 5px;
}

.content {
    height: 170px;
    overflow-y: auto;
}

.tag {
    margin: 5px 10px 5px !important;
    font-family: 'Poppins', sans-serif !important;
}

.tagSelected {
    background-color: #bfdff2 !important;
}

.tagSelectedRightPanel {
    background-color: #68b3e0 !important;
    color: #ffffff !important;
}

.deleteLabel {
    color: rgb(161, 157, 157);
    margin: 10px;
    cursor: pointer;
    text-align: right;
}

.deleteLabel:hover {
    text-decoration: underline;
}

.button {
    margin: 0 10px;
}

.searchResults {
    font-weight: bold;
    font-size: 1rem;
    padding-bottom: 5px;
    border-bottom: 1px solid #d1d1d1;
}

@media (max-width: 900px) {
    .selectedContainer {
        visibility: hidden;
    }
    .container {
        width: 100%;
    }
    .tagContainer {
        flex-direction: column;
    }
    .autocompleteStyle {
        width: 95%;
        margin: 0 10px 0 10px;
    }
}


.cardContainer {
    box-shadow: none !important;
    width: 100%;
}

.card {
    border-bottom-width: 2px;
    transition: border-bottom-color 0.3s ease;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

.card:hover {
    border-color: #f18b24;
    border-width: 1px;
    background: rgb(254, 249, 244);
}

.thumbnail {
    width: 100%;
    height: 275px;
    object-fit: cover;
}

.title {
    padding: 0px 10px;
    font-weight: bold !important;
}

.imageContainer, .card {
    position: relative;
}

.dateComments {
    position: absolute;
    background-color: #68b3e0;
    color: white;
    width: 96%;
    border-radius: 10px;
    padding: 5px 20px;
    bottom: 15px;
    left: 2%;
}

.likes {
    position: absolute;
    background-color: #f18b24;
    color: white;
    top: 15px;
    right: 15px;
    border-radius: 10px;
    padding: 5px;
    width: 60px;
    height: 35px;
    line-height: 25px;
    text-align: center;
}

.likes * {
    pointer-events: none;
}

.likes:hover {
    cursor: pointer;
    border: 2px solid silver;
    background-color: rgba(241, 139, 36, 0.75);
}

.like {
    width: 17px;
}

.comments {
    float: right;
}

.comments:hover {
    cursor: pointer;
    color: rgba(241, 139, 36, 0.75);
}

.creator {
    background-color: white;
    position: absolute;
    bottom: 0px;
    padding: 5px 20px;
    width: 100%;
    font-size: 0.85rem;
}

.card:hover .creator {
    background: rgb(254, 249, 244);
}

.creator img {
    margin-right: 10px;
}

.contentPreview {
    display: inline-block;
    max-height: 80px;
    margin: -8px 0;
    max-width: 450px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.gridContainer {
    display: flex;
    flex-direction: row;
}

.gridContainer > div {
    width: 100%;
}

.gridContainer > div:first-child {
    max-width: 300px;
    margin-right: 50px;
    /* padding-right: 5%; */
}

.gridContainer > div:first-child button {
    width: 100%;
}

.check {
    cursor: pointer;
}

.inverted {
    transform: rotate(180deg);
}

.legend {
    display: flex;
    flex-direction: column;
    background: #fff;
    margin-right: 2em;
    margin-left: 2em;
    margin-top: auto;
    padding: 5px 5px 0px 5px !important;
}

.legend1 {
    display: flex;
    flex-direction: column;
    background: #fff;
    margin-right: 2em;
    margin-left: 2em;
}

/*Resolucion Movil*/
@media (max-width: 599px) {
    .textLegend {
        margin-top: 7px;
        margin-left: 10px;
    }

    .legendDiv {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 15px;
        padding: 0px;
    }

    .legendDiv1 {
        display: flex;
        flex-direction: row;
        /*justify-item: center;*/
        margin-top: 15px;
        width: 50%;
        font-size: 12px;
    }

    .legendCheck {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-top: 10px;
    }

    .labelCheck {
        width: 50%;
        font-size: 12px;
    }

    .legendCheck2 {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
    }
}

/*Resolucion PC*/
@media (min-width: 598px) {
    .textLegend {
        margin-top: auto;
        margin-bottom: auto;
        padding-left: 10px;
    }

    .legendDiv {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        margin-top: auto;
        margin-bottom: 10px;
        font-size: 12px;
    }

    .legendDiv1 {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        margin-top: 15px;
    }

    .legendCheck2 {
        display: flex;
        font-size: 25px;
        align-items: center;
    }

    .labelCheck {
        font-size: 20px; /* Tamaño de fuente reducido para hacer el texto más pequeño */
        margin: 0 5px; /* Margen horizontal para separar labels entre sí, sin margen vertical */
        display: flex; /* Asegúrate de que el Checkbox y el texto se alinean correctamente */
        align-items: center; /* Alinea el contenido al centro verticalmente dentro del label */
        border: 1px solid #d2d2d2;
        border-radius: 20px;
        padding: 5px 20px;
    }

    .checkboxInput {
        display: none; /* Oculta el input checkbox por defecto */
    }

    .checkboxImage {
        width: 20px; /* Ancho de la imagen */
        height: 20px; /* Alto de la imagen */
        margin-left: 10px; /* Espacio entre la imagen y el texto */
        display: inline-block; /* Mostrar la imagen */
    }

    /* Estilos cuando el checkbox está marcado */
    .checkboxInput:checked + .checkboxImage {
        opacity: 1; /* Hacer la imagen completamente visible cuando está marcado */
    }

    /* Ocultar la imagen cuando el checkbox no está marcado */
    .checkboxInput:not(:checked) + .checkboxImage {
        display: none;
    }

    .legendCheck {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        margin-top: 10px;
    }
}
.filterCategory {
    font-size: 20px;
    padding: 0;
    margin-right: 30px;
    margin-left: 0;
}
