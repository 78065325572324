html,
body,
#root {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
}

* {
  scroll-margin-top: 200px;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
