.container {
  display: flex;
  flex-direction: column;
  margin: 10%;
  background-color: #fff;
  border-radius: 4px;
  width: 50vw;
  height: auto;
  min-height: 300px;
  border: 2px solid #b2b2b2;
}

.header {
  display: flex;
  justify-content: space-between;
}
.content {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 300px;
}
.title {
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  margin: 20px !important;
  color: #599ad6 !important;
}

.textAllTemplates {
  font-family: 'Poppins', sans-serif !important;
}

.divider {
  width: 100%;
  margin: 0;
}

.boxAllTemplates {
  margin: 10px;
  width: 357px;
}

.containerSwitch {
  display: flex;
  align-items: center;
}

.boxTemplates {
  display: flex;
  flex-direction: column;
}

.boxTemplate {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.textTemplate {
  font-family: 'Poppins', sans-serif !important;
  margin-top: 10px !important;
}

.textTemplateSelected {
  font-family: 'Poppins', sans-serif !important;
  margin-top: 10px !important;
  color: #fff;
}
.buttonTemplate {
  cursor: pointer;
  text-transform: none;
  margin-left: -10px !important;
  margin-right: -10px !important;
  padding: 10px !important;
}

.buttonTemplate:hover {
  background-color: #e6e6e6;
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.buttonTemplateSelected {
  cursor: pointer;
  text-transform: none;
  margin-left: -10px !important;
  margin-right: -10px !important;
  padding: 10px !important;
  background-color: #f47105;
}


.scrollableBox {
  max-height: 540px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #599ad6 #f1f4f7;
}



