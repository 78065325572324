.formContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.actionBtns {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}
