.modalContainer{
    display: flex;
    justify-content: center;
    align-items:center;
    padding-top: 5%;
}

.content{
    flex-direction: column;
    width: 1200px;
    display: flex;
    background-color: var(--color-white);
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0px 8px 20px 0px #00000026;

}

.iconContainer{
    display: flex;
    justify-content: flex-end;
    margin-right: 4px;
    width: 100%;
}


.icon{
    display:flex;
    justify-content: flex-end;
    margin-right: 4px;
    width: 15px;
    cursor: pointer;

}

.iconFilter{
    display:flex;
    justify-content: flex-end;
    margin-right: 4px;
    width: 20px;
}

.iconDownload{
    display:flex;
    justify-content: flex-end;
    margin-right: 0;
    width: 25px;
}

.filterContainer{
    display: flex;
    margin-bottom: 1rem;
    border-bottom: 3px solid #f18b24 !important;
    width: 130px;
}

.filterText{
    font-family: Montserrat-SemiBold, sans-serif !important;
    margin-left:10px !important;
    font-size: 20px !important;
    text-align: left !important;

}

.filterByCategoryText{
    font-family: Montserrat, sans-serif !important;
    font-size: 15px !important;
    text-align: left !important;
    margin-bottom: 1rem;
}

.buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.titleCheckContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 20px;
}

  .labelCheck1 {
    font-size: 18px; /* Tamaño de fuente reducido para hacer el texto más pequeño */
    margin: 0 5px; /* Margen horizontal para separar labels entre sí, sin margen vertical */
    display: flex; /* Asegúrate de que el Checkbox y el texto se alinean correctamente */
    align-items: center; /* Alinea el contenido al centro verticalmente dentro del label */
    color: #71e348;
    border: 1px solid #71e348;
    border-radius: 20px;
    padding: 5px 20px;
  }

  .labelCheck2 {
      font-size: 18px; /* Tamaño de fuente reducido para hacer el texto más pequeño */
      margin: 0 5px; /* Margen horizontal para separar labels entre sí, sin margen vertical */
      display: flex; /* Asegúrate de que el Checkbox y el texto se alinean correctamente */
      align-items: center; /* Alinea el contenido al centro verticalmente dentro del label */
      color: #ee8c38;
      border: 1px solid #ee8c38;
      border-radius: 20px;
      padding: 5px 20px;
    }

      .labelCheck3 {
          font-size: 18px; /* Tamaño de fuente reducido para hacer el texto más pequeño */
          margin: 0 5px; /* Margen horizontal para separar labels entre sí, sin margen vertical */
          display: flex; /* Asegúrate de que el Checkbox y el texto se alinean correctamente */
          align-items: center; /* Alinea el contenido al centro verticalmente dentro del label */
          color: #cc3136;
          border: 1px solid #cc3136;
          border-radius: 20px;
          padding: 5px 20px;
        }

          .labelCheck4 {
              font-size: 18px; /* Tamaño de fuente reducido para hacer el texto más pequeño */
              margin: 0 5px; /* Margen horizontal para separar labels entre sí, sin margen vertical */
              display: flex; /* Asegúrate de que el Checkbox y el texto se alinean correctamente */
              align-items: center; /* Alinea el contenido al centro verticalmente dentro del label */
              color: #b6b5b5;
              border: 1px solid #e2e1e1;
              border-radius: 20px;
              padding: 5px 20px;
            }

   .checkboxInput {
     display: none; /* Oculta el input checkbox por defecto */
   }

   .checkboxImage {
     width: 18px; /* Ancho de la imagen */
     height: 18px; /* Alto de la imagen */
     margin-left: 10px; /* Espacio entre la imagen y el texto */
     display: inline-block; /* Mostrar la imagen */
   }

   /* Estilos cuando el checkbox está marcado */
   .checkboxInput:checked + .checkboxImage {
     opacity: 1; /* Hacer la imagen completamente visible cuando está marcado */
   }

   /* Ocultar la imagen cuando el checkbox no está marcado */
   .checkboxInput:not(:checked) + .checkboxImage {
     display: none;
   }

   .titleRowContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   }

  