.generalButton {
  color: #79797a !important;
  font-weight: bold !important;
  padding: 0 !important;
  background-color: #ffffff !important;
  width: 2rem !important;
  height: 2rem !important;
  border: 1px solid rgb(203 203 203) !important;
  cursor: pointer;
}

.buttonSelected {
  color: #1b1c1d !important;
  font-weight: bold !important;
  padding: 0 !important;
  min-width: 0 !important;
  background-color: #ffffff !important;
  width: 2rem !important;
  height: 2rem !important;
  border: 1px solid rgb(203 203 203) !important;
  cursor: pointer;
}

.formatToolbar {
  display: flex;
  border-bottom: solid 1.7px rgba(199, 198, 255, 0.15);
  background-color: #f5f5f5;
  padding: 10px 0;
  margin: 0 0 10px 0;
}

.tooltipIconButton {
  background-color: #fff !important;
  min-width: 0 !important;
  border: 1px solid rgb(203 203 203) !important;
}

.selectedLink {
  box-shadow: 0 0 0 3px #ddd;
}

.textLeaf {
  padding-left: 0.1px;
}

code {
  font-family: monospace !important;
  background-color: #eee;
  padding: 3px;
}

.buttonDeleteImageInline {
  display: flex;
  border: none;
  position: absolute;
  top: -2.5em;
  left: 0.5em;
  background-color: white;
  border-radius: 7px;
}

.buttonDeleteImageNone {
  display: none;
  border: none;
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  background-color: white;
  border-radius: 7px;
}

.imageInline {
  display: block;
  max-width: 100%;
  max-height: 20em;
  box-shadow: 0 0 0 3px #b4d5ff;
}

.imageNone {
  display: block;
  max-width: 100%;
  max-height: 20em;
  box-shadow: none;
}

.iconDelete {
  width: 20px !important;
  height: 20px !important;
}

.elementLink {
  display: inline;
  position: relative;
}

.popUp {
  width: 400px;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 6px 10px;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid lightgray;
}

.buttonDeleteLink {
  border: none;
  top: 0.5em;
  left: 0.5em;
  background-color: transparent;
  border-radius: 7px;
}
