@font-face {
  font-family: 'Poppins';
  src: url('./assets/typographies/Poppins/Poppins-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/typographies/montserrat/Montserrat-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('./assets/typographies/montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./assets/typographies/opens-sans/OpenSans-Regular.ttf') format('truetype');
  font-style: normal;
}

:root {
  --color-dark-blue: #68b3e0;
  --color-light-blue: #68b3e0;
  --color-orange: #f37e31;
  --color-gray: #515151;
  --color-gray-light: #fafafa;
  --color-white: #ffffff;
  --color-black: #000;
  --color-messages: #eae9ee;
  --color-selected-table-item: #d1d1d1;
  --color-tooltip-background: #616161;
  --color-dark-blue-RGB: rgb(0, 99, 166);
  --color-light-blue-RGB: rgb(104, 179, 224);
  --color-orange-RGB: rgb(243, 126, 49);
  --color-gray-RGB: rgb(81, 81, 81);
  --color-gray-light-RGB: rgb(250, 250, 250);
  --color-messages-RGB: rgb(234, 233, 238);
  --color-selected-table-item-RGB: rgb(209, 209, 209);
  --color-tooltip-background-RGB: rgb(97, 97, 97);
  --color-light-blue: rgba(104, 179, 224, 1);
  --color-gray-text: rgba(81, 81, 81, 1);

  /*Plan Activa group color*/
  --active-plan-group-orange: #F18B24;
  --active-plan-group-orange-light: #F7C491;
  --active-plan-group-blue: #68B3E0;
  --active-plan-group-dark-blue: #0063A6;
  --active-plan-group-dark-red: #a61100;
  --active-plan-group-red: #e34231;
  --active-plan-group-dark-green: #048300;
  --active-plan-group-green: #00cc11;

  --h3-title-target-20-semibold-font-family: 'Montserrat-SemiBold', Helvetica;
  --h3-title-target-20-semibold-font-size: 20px;
  --h3-title-target-20-semibold-font-style: normal;
  --h3-title-target-20-semibold-font-weight: 600;
  --h3-title-target-20-semibold-letter-spacing: 0px;
  --h3-title-target-20-semibold-line-height: normal;
  --inputs-imput-13-bold-font-family: 'Inter-Regular', Helvetica;
  --inputs-imput-13-bold-font-size: 12px;
  --inputs-imput-13-bold-font-style: normal;
  --inputs-imput-13-bold-font-weight: 400;
  --inputs-imput-13-bold-letter-spacing: 0px;
  --inputs-imput-13-bold-line-height: normal;
  --p1-paragraph-16-regular-font-family: 'OpenSans-Regular', Helvetica;
  --p1-paragraph-16-regular-font-size: 16px;
  --p1-paragraph-16-regular-font-style: normal;
  --p1-paragraph-16-regular-font-weight: 400;
  --p1-paragraph-16-regular-letter-spacing: 0px;
  --p1-paragraph-16-regular-line-height: 24px;
  --titulo-table-16-bold-font-family: 'Montserrat-Bold', Helvetica;
  --titulo-table-16-bold-font-size: 16px;
  --titulo-table-16-bold-font-style: normal;
  --titulo-table-16-bold-font-weight: 700;
  --titulo-table-16-bold-letter-spacing: 0px;
  --titulo-table-16-bold-line-height: 24px;
}

h1,
h2 {
  color: var(--color-dark-blue);
}

h3,
h4,
h5,
h6 {
  color: var(--color-gray);
}

p {
  color: var(--color-gray);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-gray);
  background-color: var(--color-gray-light);
  font-family: 'Poppins', sans-serif !important;
}

code {
  font-family: 'Poppins', sans-serif !important;
}

main {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.MuiModal-root{
    overflow: auto !important;
}
