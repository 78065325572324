.checkboxStyle {
  background-color: #fff;
  border: 2px solid #68b3e0;
  border-radius: 25px;
  height: 180px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.exitIconStyles {
  cursor: pointer;
  justify-content: flex-end;
}
