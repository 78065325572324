.container {
  display: flex;
  flex-direction: column;
  margin: 10%;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  width: 65vw;
  height: auto;
  position: fixed;
}

.containerSecondModal {
  display: flex;
  flex-direction: column;
  margin: 15%;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  width: 60vw;
  height: auto;
  position: fixed;
}

.containerRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

@media only screen and (max-width: 900px) {
  .containerRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.modalOverFlow {
  display: flex;
  overflow: scroll;
  justify-content: center;
}

.contentHeader {
  display: flex;
  justify-content: flex-end;
  margin: 8px;
  color: #68b3e0;
}

.headerTitle {
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  font-size: 18px !important;
  padding: 15px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.addButton {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.deleteButton{
  margin-right: 20px;
}

.field {
  display: flex;
  justify-content: space-between;
  margin: 8px;
  align-items: center;
}

.fieldEmail {
  display: flex;
  justify-content: space-between;
  margin: 8px;
  align-items: center;
  flex-grow: 1;
}

.fieldTitle {
  font-family: 'Poppins', sans-serif;
  margin-right: 8px;
  margin-left: 15px;
  width: 100px;
}

.fieldTitleEmail {
  font-family: 'Poppins', sans-serif;
  margin-right: 8px;
  margin-left: 15px;
  width: 310px;
}

.dividerStyle {
  margin-right: 15px;
  margin-left: 15px;
  border-color: #f47105;
  margin-bottom: 15px;
}
