.container {
}

.title {
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  font-size: 16px !important;
  padding: 15px;
}

.fieldTitle {
  padding-right: 10px;
  flex-wrap: nowrap;
  width: 70px;
}

.fieldTitleLarge {
  padding-right: 10px;
  white-space: nowrap;
}

.contentFieldsCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 50%;
}

.contentFieldsCardHour {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.containerHour {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: 1px solid #bfc1c2 !important;
  border-radius: 4px;
}

.fieldsCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.card {
  background-color: #f7fbfd !important;
  border: 1px solid #68b3e0 !important;
  border-radius: 8px !important;
}

.icon {
  width: 40px;
  height: 40px;
  display: flex;
}

.iconImage {
  width: 100%;
}

.buttonDisapear {
  display: none;
}
