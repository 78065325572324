.container {
  background-color: #ffffff;
  flex-direction: column;
  display: flex;
}

.headerTable {
  display: flex;
  justify-content: space-between;
}
