.container {
  margin-top: 80px;
  margin-right: 50px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
}

.textField {
  margin-bottom: 20px;
  width: 50%;
}

.centerBlock2 {
  text-align: left !important;
  display: block !important;
  margin: 32px 0px;
}

.customBorder {
  border-bottom: 3px solid #68b3e0 !important;
  font-size: 300px !important;
}

.containerInput {
  display: flex;
  justify-content: space-between;
  max-width: 92%;
  margin: 20px auto;
}

.containerChecks {
  display: flex;
  justify-content: space-between;
  max-width: 88%;
  margin: 20px auto 20px 1rem;
}

.test {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

input[type='checkbox'] {
  appearance: none;
  width: 30px;
  height: 30px;
  border: 1px solid #999;
  outline: none;
  transition: 0.3s;
  border-radius: 25%;
}

input[type='checkbox']:before {
  content: '';
  display: block;
  width: 90%;
  height: 90%;
  margin: 5% auto;
  border-radius: 25%;
}

input[type='radio'] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  outline: none;
  transition: 0.3s;
  border-radius: 50%;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 89%;
  height: 89%;
  margin: 5% auto;
  border-radius: 50%;
}

input[type='checkbox']:checked:before {
  background: #f18b24;
}

input[type='checkbox']:disabled:before {
  background: #e6e6e6;
}

.invisibleCheckbox {
  visibility: hidden;
}

.formTableContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fff;
  margin: 2em;
  padding: 0 5px 0 5px !important;
}

.symptomsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background: #fff;
  margin: 2em;
  padding: 0 5px 0 5px !important;
}

.symptomName {
  color: #b6b5b5;
  font-family: 'OpenSans', serif;
  font-size: 16px;
  font-style: normal;
}

.modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Para un fondo semi-transparente, si lo deseas */
  z-index: 999;
}

.modalBoxContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  width: 100%;
  height: 100%;
  border: 1px solid red;
}

.rowDirectoryLabel {
  font-size: 16px;
  font-family: sans-serif;
  color: #000000de;
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  margin: 8px;
  margin-bottom: 30px;
}

.contentHeaderExitIcon {
  display: flex;
  justify-content: flex-end;
}

.modalOverFlow {
  overflow: scroll;
}

.titleLabel {
  font-weight: bold;
}

.celdaSinBorde {
  border-bottom: none !important;
}

.MuiTableRow-root td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.MuiTableRow-root td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  margin: 5% 20% 10% 20%;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
}

.modalContainerTemplate {
  display: flex;
  flex-direction: column;
  margin: 5% 20% auto 20%;
  background-color: #fff;
  border-radius: 4px;
  padding: 24px 48px;
}

.cardContainer {
  box-shadow: none !important;
  width: 100%;
}

.card {
  border-bottom-width: 2px;
  transition: border-bottom-color 0.3s ease;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.selectedCard {
  border-color: #f18b24 !important;
  border-width: 2px !important;
  background: rgb(254, 249, 244) !important;
  height: 250px;
  display: flex;
}
.selectedIconsCard {
  border-color: #f18b24 !important;
  border-width: 2px !important;
  background: rgb(254, 249, 244) !important;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.card:hover {
  border-color: #f18b24;
  border-width: 1px;
  background: rgb(254, 249, 244);
}

.textBlock {
  color: #000000;
  margin-left: 30px;
  font-size: 21px;
  margin-bottom: 50px;
  width: 120px;
}

.boldHeader {
  font-weight: bold;
}

.otherSpecialityContainer {
  display: flex;
  margin: 20px 0px;
  align-items: center;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.boxContainerSymptoms {
  border: 1px solid rgba(219, 218, 218, 0.8);
  border-radius: 6px;
  width: 100%;
  display: flex;
  padding: 16px 0;
  margin-bottom: 1rem;
  align-items: center;
}

.leftContent {
  width: 45%;
  padding: 0 0 0 5%;
  display: flex;
}

.middleContent {
  width: 40%;
  display: flex;
  gap: 1rem;
}

.rightContent {
  width: 15%;
  display: flex;
  justify-content: center;
}

.smallImage {
  width: 50px;
  height: 50px;
}

.iconImage {
  width: 24px;
  height: 24px;
}
