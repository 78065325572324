.divHeader {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 1rem;

  border-bottom: 2px solid #f37e31;
  justify-content: space-between;
}

.divHeaderLeft > * {
  margin-left: 1rem;
}

.contactFullName {
  margin-right: 1rem;
  font-weight: bold;
  font-size: 16px;
}

.tooltipConversationUsers {
  margin-left: 1rem;
}

.conversationLeft {
  display: flex;
  flex-flow: column;
  align-items: end;
}

.conversationLeft p {
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.conversationOptions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 0;
  width: 100%;
}

.conversationOptions > div {
  width: 2rem;
  display: flex;
  justify-content: center;
}

.MuiAvatar-fallback {
  background-color: #68b3e0;
}
.creatorResponsive {
  display: none;
}

@media (max-width: 599px) {
  .divHeader {
    padding: 1rem 0;
    flex-direction: column;
    align-items: start;
  }
  .conversationLeft {
    display: none;
  }
  .creatorResponsive {
    display: block;
    padding: 1rem 0;
  }
}
