.modalContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
    background-color: #fff;
    border-radius: 8px;
    padding: 2rem;
    width: 600px;
    height: fit-content;
    z-index: 1301 !important;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
}

.iconContainer{
    display: flex;
    justify-content: flex-end;
    margin-right: 4px;
    width: 100%;
}

.icon{
    display:flex;
    justify-content: flex-end;
    margin-right: 4px;
    width: 15px;
    cursor: pointer;
}

.description {
    padding: 1rem 0;
    font-family: 'OpenSans', sans-serif !important;
}

.checkbox > span{
    font-family: 'OpenSans', sans-serif !important;
    color: #515151;
}

.checkboxContainer {
    display: flex;
    flex-direction: column;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}