.container {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  border-bottom: 3px solid #f18b24;
  width: 100% !important;
}

.containerNoBorder {
  border: none;
}

.header {
  font-weight: bold;
  font-size: 1.5rem;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}

.icon {
  margin-bottom: 8px;
  cursor: pointer;
  width: 20px;
  margin-right: 4px;
}

.circleUser {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 0;
}

/*resolucion mobil*/
@media (max-width: 599px) {
  .header {
    font-weight: bold;
    font-size: 1.3rem;
    font-family: Montserrat, sans-serif;
  }
}
