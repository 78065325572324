.box {
  display: flex;
  justify-content: space-between;
}

.headerContainer {
  width: 90%;
  margin: 4px auto auto;
}

.textField {
  height: 600px;
}

.listBox {
  width: 500px;
  height: 670px;
  background-color: white;
}

.mapContainer {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: bold;
  font-size: 1rem;
  border-bottom: 3px solid var(--color-light-blue);
  margin: 10px 15px;
  padding-bottom: 5px;
}

.switch {
  width: 90%;
  margin: 0 15px;
  border-bottom: 1px solid #d1d1d1;
  font-family: 'Poppins', sans-serif !important;
}

.listElements {
  overflow-y: auto;
  height: 500px;
}

.listElement {
  border-bottom: 1px solid #d1d1d1;
  margin-left: 10px;
  margin-right: 10px;
}

.selectedElement {
  background-color: #f2f9fd;
}

.dentistName {
  color: var(--color-dark-blue);
  font-weight: bold;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif !important;
}

.dentistInfo {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif !important;
}

.dentistLastInfo {
  display: inline;
  margin-top: 0;
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif !important;
}

.privateInfo {
  float: right;
  font-size: 0.75rem;
  font-family: 'Poppins', sans-serif !important;
}

.privateIcon {
  vertical-align: middle;
  margin-bottom: 2px;
}

.mapBox {
  min-width: calc(90% - 200px);
  margin-right: 15px;
  display: inherit;
}

.viewBox {
  display: none;
  width: 90%;
  margin: auto;
}

.viewSwitch {
  color: #68b3e0 !important;
  font-size: 12px !important;
}

.viewIcon {
  width: 30px !important;
}

@media (max-width: 900px) {
  .box {
    margin: 10px 5px 5px 5px;
    padding: 0 !important;
    flex-direction: column;
  }
  .listElements {
    display: none;
    padding: 0 12px 0 12px;

  }
  .viewBox {
    display: flex;
    flex-direction: row;
    margin:12px
  }
  .listBox {
    width: 100%;
    height: fit-content;
  }
  .mapBox {
    min-width: 100%;
    margin-right: 0;
    background-color: #ffffff;
  }
  .listElement{
    border: 1px solid #68b3e0;
    border-radius: 10px;
    margin: 0 10px 5px 10px;
    padding: 5px;
  }

}
