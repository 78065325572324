.modalView {
  flex-direction: column;
  display: flex;
  background-color: var(--color-white);
  gap: 1.5rem;
  padding: 1.5rem;
  min-height: 100vh;
}

.modalBox {
  margin-top: 20px;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  padding: 0.25rem 0 0.25rem 0.75rem;
  gap: 1rem;
  font-size: 14px;
}

.titleLabel {
  width: 100%;
  font-weight: bold;
}

.label {
  width: 100%;
  font-family: 'Poppins', sans-serif;
}

.labelResponsive {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  text-align: right;
}
.labelMedication {
  text-align: left;
  font-family: 'Poppins', sans-serif;
}

.labelTitle {
  width: 100%;
  color: #F18B24;
  text-align: left;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  margin-bottom: 0.8rem;
  position: relative;
}

.labelTitle::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 35px; /* Ajusta este valor al ancho deseado */
  height: 1px;
  background-color: #F18B24;
}

.labelDescription {
  font-family: 'Poppins', sans-serif;
}

.chip {
  margin: 3px 5px 3px 0;
  background-color: #0063a6 !important;
  color: white !important;
}

div.header div p {
  color: #68b3e0;
  margin-left: 0;
  margin-bottom: 8px;
}
.icon {
  cursor: pointer;
}
.exitIcon {
  display: flex;
  justify-content: flex-end;
}

.radio {
  border-radius: 100%;
  border-color: black;
  border-style: solid;
  border-width: 2px;
  width: 17px;
  height: 17px;
  margin: 0px 5px 0px 0px;
  padding: 0px;
  background-image: radial-gradient(circle, black 45%, white 55%);
}

.dayLabelg {
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: #00c617 !important;
}

.dayLabely {
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: #f7b500 !important;
}

.dayLabelr {
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: #e02020 !important;
}

.divColumn {
  display: flex;
  flex-direction: column;
  min-width: 30%;
}

.divColumnResponsive {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 30%;
}

.divRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  font-size: 14px;
}

.color {
  background-color: white;
  border-radius: 5px;
  color: white;
  margin: 0 5px 0 5px;
  padding: 0 5px 0 5px;
}

.labelTypeCrisis {
  width: 50%;
  font-family: 'Poppins', sans-serif;
  margin-right: 94px !important;
  font-weight: 900;
  color: #6b6b6c;
}

.labelTypeCrisisResponsive {
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  color: #6b6b6c;
}

.labelSintoma {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 10px 1em 10px;
  padding: 0;
}

.labelSintomaResponsive {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0;
}

.labelSintoma p {
  margin: 0;
  padding: 0;
}

.containerRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.eventContainer {
  padding: 1rem;
  border: 1px solid rgb(189, 189, 189);
  border-radius: 10px;
}

.mediaDetails {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.actionBtns {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
