.navbarContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 1rem 1rem 1rem;
  background: #fff;
  height: 100%;
  gap: 10px;
  position: relative;
}
.cardItem {
  display: flex;
  align-items: center;
  justify-items: flex-start;
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 20px !important;
  border: 1px solid #68b3e0;
}

.exitIcon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
}
.navbar {
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #ddd;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.navbarMenu {
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
  list-style: none;
}
.navbarItem {
  flex: 1;
  text-align: center !important;
  justify-content: center !important;
  align-items: flex-start !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.navbarLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  color: #333;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
}

.navbarLink:hover {
  background-color: #f0f0f0;
}

.navbarText {
  margin-top: 5px;
}

@media (min-width: 600px) {
  .navbar {
    bottom: auto;
    top: 0;
  }
}

.preProfileContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-style: solid;
  border-width: 1px;
  border-color: #6db3dd;
  border-radius: 20px;
  padding: 50px;
}

.mainContainerRow {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: calc(100% - 80px);
  background-color: #ffffff;
}

.bordedMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 94%;
  margin-left: 3%;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2% 0 2% 0;
}

.backResponsiveContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  padding: 20px;
  border-bottom: solid #d9d9d9 1px;
  margin-bottom: 20px;
}

.backResponsiveTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 22px;
  color: #454545;
}

.boxColumnImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 12px;
}

.boxTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
}
