.blob {
  border-radius: 50%;
  margin: 0;
  height: 15px;
  width: 15px;
  transform: scale(1);
  background: rgba(255, 121, 63, 1);
  box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
  animation: pulse-orange 2s infinite;
}

.hiddenBlob {
  border-radius: 50%;
  margin: 0;
  height: 15px;
  width: 15px;
  transform: scale(1);
  background: rgba(255, 121, 63, 0);
}

.borderTable {
  border-color: #68b3e0 !important;
}

.cell {
  cursor: pointer;
}

.userBoxResponsive {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bold {
  font-weight: bold;
}

@keyframes pulse-orange {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
}
