.mainContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  background-color: #fafafb;
}

/* TO AVOID SET PADDING IN ALL FEATURES WITHOUT NEED TO PUT CSS IN ROUTER */
/* IT'S SET PADDING IN MAIN CONTAINER CHILD */
.mainContainer > * > * {
  padding: 1.5rem;
}

@media(max-width: 598px){
  .mainContainer{
    overflow-y: auto;
    overflow-x: hidden;
  }
  .mainContainer > * > * {
    padding: 0.5rem;
  }
}
