.header > * {
  /*min-width: 100%;
      max-width: 100%;*/
}

.header p {
  margin: 0;
  color: var(--color-white);
}

img {
  width: auto;
  height: auto;
}

.headerTop {
  width: 100%;
  height: 88px;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 28px 48px 28px 47px;
}

.containerNav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerTop > div {
  width: 100%;
}

.logoHeader {
  position: absolute;
  width: 912px;
  height: 100%;
}

.headerTopLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.headerTopLeft img {
  width: 120px;
  height: 31px;
  z-index: 99;
}

.headerTopLeft p {
  margin: 0 0 0 1rem;
  width: 380px;
  display: inline-block;
  z-index: 99;
  color: #4d4d4d;
  font-family: Poppins, sans-serif;
  font-weight: 900;
  font-size: 2rem;
  letter-spacing: 2px;
}
.headerTopRight {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-bottom: 4px;
}
.headerTopRight img {
  /*  margin: 0.5rem 1rem -0.5rem 0;*/
  width: auto;
  height: 27px;
  float: right;
  margin-right: 20px;
}

.headerBottomMobile {
  display: none !important;
  height: 25px;
  background-color: #68b3e0 !important;
  align-content: center;
  box-shadow: none;
}

.nameIconMobile {
  width: 15px;
  margin: 5px 10px 5px 15px;
}

.menuLink {
  text-decoration: none;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
}

.menuIcon {
  width: 18px;
  margin-left: 5px;
}

.headerBottom {
  height: auto;
  /*  margin-top: 1%;*/
}

.headerNav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  width: 100%;
  background-color: #f5f5f7 !important;
  position: relative;
  padding-left: 46px;
  padding-right: 50px;
}

.headerBottomLeft {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  column-gap: 1.5rem;
  row-gap: 1rem;
  min-height: 0 !important;
  background-color: #f5f5f7;
  border: 0.5rem solid #f5f5f7;
  justify-content: flex-start;
  position: relative;
}

.genericTooltip {
  background-color: rgb(var(--color-tooltip-background-RGB));
}

.genericTooltip > p {
  color: var(--color-white);
}

.userLogo {
  height: 1.5rem;
  width: 1.5rem;
}

.headerBottomMiddle {
  column-gap: 0.3rem;
  row-gap: 1rem;
  padding: 0.5rem;
  background-color: var(--color-dark-blue);
  position: relative;
}

.headerBottomMiddle:before {
  content: '';
  position: absolute;
  top: 0;
  left: -50px;
  border-bottom: 44px solid var(--color-dark-blue);
  border-left: 50px solid transparent;
  width: 0;
}

.headerBottomMiddle > * {
  cursor: pointer;
}

.headerBottomRight {
  padding: 0.5rem;
  column-gap: 0.5rem;
  background-color: var(--color-orange);
  cursor: pointer;
  position: relative;
}

.headerBottomRight:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-top: 44px solid var(--color-dark-blue);
  border-right: 50px solid transparent;
  width: 0;
}

.icon {
  width: 20px;
}

.nameIcon {
  width: 20px;
  margin-right: 20px;
}

.genderIcon {
  width: 20px;
  margin-left: 20px;
}

.headerDropdown {
  transition: ease all 0.4s;
}

/* Safari 10+ */

/* @media not all and (min-resolution: 0.001dpcm) {
  @media {
    .headerTopLeft img {
      display: flex;
      flex-direction: row;
      justify-content: center;
      max-width: 170px;
    }

    .headerTopLeft p {
      max-width: 600px;
    }

    .headerTopRight img {
      max-width: 400px;
    }
  }
} */

@media (max-width: 1225px) {
  .headerBottomMiddle:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 0;
    border-left: 0;
    width: 0;
  }

  .headerBottomRight:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-top: 0;
    border-right: 0;
    width: 0;
  }
}

@media (max-width: 1600px) {
  .logoHeader {
    position: absolute;
    max-width: 800px;
    height: calc(100% + 40px);
    transition: 300ms;
  }
}

@media (max-width: 1300px) {
  .headerTopRight img:nth-child(2) {
    display: none;
  }

  .headerTopRight img:nth-child(1) {
    /*background: #ffffff;*/
  }
}

@media (max-width: 1000px) {
  .logoHeader {
    display: none;
  }

  .headerTop {
    background: #166cb3;
  }

  .headerTopRight img:nth-child(1) {
    display: none;
  }

  .mainContainer {
    background: #39f !important;
  }
}

@media (max-width: 1000px) {
  .headerTop {
    height: 60px;
    padding-top: 10px;
  }

  .headerTopLeft {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .headerTopLeft p {
    width: 100%;
    font-size: 18px;
    line-height: 26px;
    padding-bottom: 13px;
  }

  .headerBottomLeft {
    display: none !important;
  }

  .headerBottomMiddle {
    display: none !important;
  }

  .headerBottomRight {
    display: none !important;
  }

  .headerBottomRight p {
    display: none !important;
  }

  .headerTopLeft img {
    display: none;
  }

  .headerTopRight {
    display: none !important;
  }

  .headerBottomMobile {
    display: flex !important;
  }
}

/*resolucion movil*/
@media(max-width: 599px) {
  .headerTop {
    height: 60px;
    padding: 0 0 0 20px;
    background-color: white;
  }

  .headerTopLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .headerTopLeft img {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 120px;
    height: 60px;
  }

  .headerNav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 1px;
    width: 100%;
    background-color: grey !important;
    position: relative;

  }
}
