.containerInside {
  flex-direction: column;
  width: 650px;
  display: flex;
  background-color: var(--color-white);
  padding: 1.5rem;
}

.agoraContainer {
  display: flex;
  justify-content: center;
  padding-top: 13%;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 38%;
  margin-left: 31%;
  padding-top: 3%;
}

.buttonsCancel {
  display: flex;
  justify-content: space-between;
  width: 38%;
  margin-left: 40%;
  padding-top: 3%;
}

.alert {
  height: 90px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 3.5%;
}

.deleteContainer{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px; /*Aumenta el espacio alrededor del contenido del modal*/
  min-width: 33%; /*Aumenta el ancho máximo del modal*/
  text-align: center; /*Centra el contenido*/
}

.buttonsBox{
  display: flex;
  justify-content: space-around;
  margin-top: 70px;
}

.containerInside form{
  width: 100%;
}

@media (max-width: 599px) {
  .buttons {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-left: 2%;
    padding-top: 3%;

  }

  .containerInside {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--color-white);
    padding: 1rem;
  }

  .buttonsBox{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 70px;
    width: 100%;
  }
}
