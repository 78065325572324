.newConversationContainer {
  flex-direction: column;
  display: flex;
  background-color: var(--color-white);
  padding: 1.5rem 2rem;
  height: auto;
}

.newConversation {
  flex-direction: column;
  width: 1000px;
  display: flex;
  background-color: var(--color-white);
  padding: 1.5rem 2rem;
  height: auto;
}

.nuevo {
  height: 100% !important;
  width: 100% !important;
}

.modalNewConversation {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
  margin: 10px;
}
.datePicker {
  margin-right: 0px !important;
  border: 1px solid #d1d1d1 !important;
  padding: 4px !important;
  border-radius: 4px !important;
}

.label {
  margin: 0 !important;
  cursor: auto;
}
.label span {
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

.roleIcon {
  padding-right: 10px;
  width: 25px;
}

.chip {
  margin: 3px 5px;
}

.title {
  margin: 1em 0 1rem 0.5rem;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

.paragraph {
  margin: 0;
}

.actionBtns {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 1rem;
}

.inputMarginLeft {
  margin-left: 2px;
  width: 350px;
}

.inputFormcontrol {
  margin-left: 1rem !important;
}

@media (max-width: 599px) {
  .inputFormcontrol,
  .modalNewConversation {
    margin: 0 !important;
  }
  .title,
  .label span {
    font-size: 0.85rem;
  }
  .label,
  .inputMarginLeft,
  .inputFormcontrol {
    width: 100%;
  }
  .actionBtns {
    margin-top: 1rem;
    justify-content: space-between;
  }
}
