.wallContainer {
  display: flex;
  flex-direction: row;
  background-color: #fafafa;
  height: 80%;
}

.notificationContainer {
    overflow: auto;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
  border-radius: 2px;
  height: 100%;
  width: 100%;
  max-height: 100%;
}

.audivisualContent {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
  border-radius: 2px;
  height: 60%;
  width: 100%;
}

.horizontalTimeline {
  height: 100%;
  width: 100%;
  max-height: 100%;
}

.test2 {
  overflow: auto;
  margin-top: 0;
  padding-top: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row !important;
  width: 100%;
  height: 90%;
}

.chatWidgetContainer {
  background-color: #ffffff;
  padding: 20px;
  margin: 20px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  width: 30%;
  max-height: 100%;
}

.screen{
    width: 90%;
    align-content: center;
    text-align: center;
}

.scree-Icon{
    color:rgb(0, 69, 116)
}

.test1 {
  width: 90%;
  height: 100%;
  /* border: 1px solid red;*/
}

.chatIcon {
  width: 35px;
}

.arrowRight {
  width: 13px;
  margin: 0 5px 0 5px;
}

.noNotifications {
  text-align: center;
  font-size: 1.8rem;
}

.pagination {
  margin-right: auto;
  margin-left: auto;
}

.icon {
  width: 25px;
}

.containerFooter {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  color: #68b3e0;
}

.containerFooter h3 {
  font-size: 14px;
  font-weight: normal;
  color: #68b3e0;
  text-decoration: none;
  transition: all ease 0.4s;
}

.notificationBox {
  padding: 10px;
  height: 100% !important;
  max-height: 100%;
  /*border: 1px solid green;*/
  display: flex;
  width: 50vh;

  flex-direction: column; /* Added */
}

/*.notificationBox:before {
  content: '';
  display: block;
  position: absolute;
  top: 44.3%;
  right: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right-color: #d1d1d1;
}

.notificationBox:after {
  content: '';
  display: block;
  position: absolute;
  right: 100%;
  top: 45%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-right-color: white;
}*/

.conversationWall {
    width: 99%;
    margin-top: 15px;
    background-color: #ffffff;
    cursor: pointer;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    /*border: 1px solid #68b3e0;*/
}

.notificationTitle {
    margin: 5px auto 1px 10px;
    font-size: 20px;
}

.notificationsWall {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
    background-color: #ffffff;
    cursor: pointer;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    /*border: 1px solid #68b3e0;*/
}

.blueSeparator{
    border-bottom: 1px solid #68b3e0;
    margin: 1px 10px 1px 5px;
}

.avatarWrapper {
    /*display: flex;*/
    margin: 1px 1px 10px 5px;
}

.resendButton {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    span{
        margin-right: 10px;
    }
    span:hover{
        border-radius: 5px;
        color:white;
        background-color: #166bb2;
    }
}

.notificationDescription{
    margin: 5px auto 1px auto;
}

.notificationBlock {
    margin: 10px auto 1px 10px;
    width: 100%;
}

/*.notificationContainer {*/
/*    background-color: #ffffff;*/
/*    padding: 20px;*/
/*    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);*/
/*    margin-bottom: 20px;*/
/*    border-radius: 2px;*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    max-height: 100%;*/
/*}*/

.timelineDot {
  width: 25px;
  height: 25px;
  border-width: 4px;
}

@media (max-width: 1200px) {
  .wallContainer {
    flex-direction: column;
  }

  .notificationBox {
    width: auto;
  }

  .notificationContainer {
    margin: 10px 0 0 0;
    width: auto;
  }

  .chatWidgetContainer {
    margin: 0 0 10px 0;
    width: auto;
  }
}

@media (max-width: 600px) {
  .notificationBox h2 {
    justify-content: left;
  }
}

@media (max-width: 410px) {
  .containerFooter {
    display: flex;
    flex-flow: column;
    align-items: end;
  }
}
