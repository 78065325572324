.modalContainer {
  display: flex;
  flex-direction: column;
  margin: 1rem 8rem;
  background-color: #fff;
  border-radius: 4px;
  padding: 1rem;
}

.contentHeaderExitIcon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  margin: 8px;
  margin-bottom: 30px;
  border-bottom: 2px solid #f18b24;
}

.titleModalTreatments {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.formTreatments {
  padding: 0.5rem 0.5rem 0 0;
}

.inputFormTreatments {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

.mainModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 10px;
  margin-left: 10px;
}

.modalOverFlow {
  overflow: scroll;
}

.doseBox {
  font-family: 'Poppins', serif;
  padding: 30px 20px;
  border: 2px solid #68b3e0;
  width: 100%;
  row-gap: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.centeredCol {
  display: flex;
  align-items: center;
}
.checkboxContainer {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
}

.radioContainer {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  align-items: flex-start;
}

.check {
  min-width: 20px;
  min-height: 20px;
  height: 26px;
  width: 26px;
}
.doseLabel {
  display: flex;
  align-items: center;
}
.alert {
  margin-top: 20px;
}

.boxesForm {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  align-items: center;
  gap: 4rem;
  justify-content: flex-start;
}

.boxesForm2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
}

.containerCol4 {
  width: 25%;
}

.containerCol12 {
  width: 100%;
}

.containerCol3 {
  width: calc(100% / 3);
}

.containerCol6 {
  width: calc(100% / 6);
}

.highValue {
  margin-bottom: 1rem;
  margin-left: 0;
  font-weight: bold;
}

/*mobile resolution*/
@media (max-width: 1030px) {
  .modal {
    padding: 20px;
  }
  .modalContainer {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 0;
    background-color: #fff;
    padding: 4%;
    width: 100%;
    height: 100%;
    overflow: scroll;
    border-radius: 0 !important;
  }

  .inputFormTreatments {
    display: flex;
    align-items: center;
  }

  .boxesForm {
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .boxesForm2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 3rem;
    width: 100%;
    margin-right: 0;
  }

  .marginTopText {
    margin-top: 15px;
  }

  .doseField {
    width: 100%;
  }
  .doseField2 {
    width: 100%;
    min-width: 128px;
  }

  .doseBox {
    font-family: 'Poppins', serif;
    padding: 10px 5px;
    border: none;
    width: 100%;
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .centeredCol {
    display: block;
  }
  .containerCol4 {
    width: 100%;
    padding-top: 10px;
  }

  .containerCol3 {
    width: 100%;
    padding-top: 10px;
  }

  .containerCol6 {
    width: 100%;
    padding-top: 10px;
  }

  .doseLabel {
    display: flex;
    justify-content: flex-start;
  }

  .checkboxContainer {
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;
  }

  .inputDuration {
    min-width: 100px;
    width: 30%;
  }

  .mainModalContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
