.containerTableUsers {
  display: flex;
}

.containerActions {
  display: flex;
  flex-direction: column;
}

.containerSearch {
  display: flex;
  padding: 2rem 0;
  border-bottom: solid 1px grey;
}
.containerAction {
  display: flex;
  padding: 1rem 0;
  flex-direction: column;
  gap: 1rem;
  border-bottom: solid 1px grey;
}

.containerParticipants {
  flex: 3;
  display: flex;
  flex-direction: column;
  padding: 0 4rem;
}

.containerPendings {
  margin: 2rem 0;
}

.roleContainer {
  align-self: flex-end;
  display: flex;
  margin-top: 4rem;
  align-items: center;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  padding: 1rem 0;
  gap: 16px;
}

.role {
  display: flex;
  align-items: center;
  gap: 4px;
}

.title {
  margin: 0;
  font-size: 20px;
  border-bottom: solid 3px #f18b24;
  font-weight: bold;
}

.searchText{
  display: none
}

.table {
  border-collapse: separate !important;
  border-spacing: 0 1rem !important;
}

.row {
  box-shadow: 4px 4px 10px rgba(0.4, 0, 0, 0.1);
}

.headCell,
.firtsBodyCell,
.middleBodyCell,
.lastBodyCell,
.statusBodyCellActive,
.statusBodyCellActiveExpired
 {
  margin-top: 1rem;
  margin-left: 10px !important;
  background-color: #FFFFFF;
  color: #515151;
  line-height: 24px !important;
  padding: 1.5rem 0 !important;
  text-align: center !important;
  border-color: #68B3E0 !important;
  font-family: 'Montserrat-SemiBold', sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.headCell{
  border: none !important;
}


.firtsBodyCell { 
  border-left: 1px solid;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  
}

.middleBodyCell {
  border-bottom: 1px solid;
  border-top: 1px solid;
}


.lastBodyCell {
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.statusBodyCellActive {
  color: green !important;
  border-bottom: 1px solid;
  border-top: 1px solid;
}

.statusBodyCellActiveExpired {
  color: red !important;
  border-bottom: 1px solid;
  border-top: 1px solid;
}

.displayNone {
  display: none !important;
}  

/*Resolucion Tablet*/
@media (max-width: 1024px) {
  .containerActions {
    display: flex;
    flex-direction: column;
  }

  .containerTableUsers {
    display: flex;
    flex-direction: column;
  }

  .containerSearch {
    display: flex;
    width: 100%;
    gap: 10px;
    padding-top: 24px;
    padding-bottom: 0;
    border: none;
  }

  .searchText{
    display: flex;
    margin-top: -2px;
    width: 70px;
  }

  form {
    width:30%;
  }

  .head{
    display: none !important;
  }

  .containerAction {
    display: flex;
    padding-top: 24px;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1.5rem;
    border: none;
  }

  .containerAction2{
    display: flex;
    width: 99%;
    flex-direction: row;
    gap: 2rem;
  }

  .containerParticipants {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .roleContainer {
    display: none;
  }

  .title {
    width: 50%;
  }

  .chipRol{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 5px !important;
    border-radius: 5px;
  }

  .chipRoleRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
  }

  .firtsBodyCell,
  .middleBodyCell,
  .lastBodyCell {
    padding: 0.5rem !important;
    text-align: left !important;
  }

}

/*Resolucion Movil*/
@media (max-width: 599px) {
.containerAction {
    display: flex;
    padding: 2rem 0;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    border-bottom: solid 1px grey;
  }

  .title {
    width: 100%;
  }
  form {
    width:100%;
  }

}
