.modal {
  display: flex;
  overflow: auto;
  justify-content: center;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 10px;
}

.formModal {
  background-color: #fff;
  padding: 2rem;
  border-radius: 4px;;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 10%;
  background-color: #fff;
  border-radius: 4px;
  padding: 25px;
  font-family: inherit;
}

.titleNewCrisis {
  margin: 0;
  width: 100%;
  color: #68b3e0;
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  color: #68b3e0;
  border-bottom: 3px solid #68b3e0;
}

.boxLevel {
  width: 40px;
  height: 30px;
  border-radius: 8px;
}

.containerRadioButton {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.containerWithSelect {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 32px 0;
  gap: 32px;
}

.boxAfterCrisis {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.inputSelect>div {
  width: 200px !important;
}

.buttonSaveNewtypeCrisis {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.duration {
  display: flex;
}

.modalOverFlow {
  overflow: auto;
}

.radioGroup {
  font-family: 'Poppins', sans-serif !important;
}

.textSelect {
  display: block;
  font-size: 20px !important;
  font-weight: bold !important;
  unicode-bidi: isolate;
  background-color: white;
  font-family: 'Poppins', sans-serif !important;
}

.textImage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.otherSympthoms {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 210px;
}

.buttonSaveContainer {
  display: flex;
  justify-content: flex-end;
}

.divider {
  background-color: #045383;
  margin-top: 30px;
  margin-left: 8px;
  margin-right: 8px;
}

@media (max-width: 599px) {
  .modalContainer {
    margin: 0;
    padding: 0;
  }

  .formModal {
      border-radius: 0;
  }

  .container {
    margin: 0;
    background-color: #fff;
    width: 100%;
    height: calc(100% - 104px);
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 0 !important;
    padding: 5%;
  }

  .containerRadioButton {
    flex-direction: row;
  }

  .containerWithSelect {
    flex-direction: column;
    row-gap: 25px;
  }

  .textImage {
    align-items: flex-start;
  }

  .textImage>div>img {
    margin-left: 50px;
  }

  .otherSympthoms {
    display: block;
    height: auto;
  }

  .buttonSaveNewtypeCrisis {
    display: block;
  }
  .buttonSaveContainer {
    justify-content: center;
  }

}