.icon {
  vertical-align: middle;
  margin-right: 10px;
}

.dir {
  cursor: pointer;
}

.backButton {
  margin-bottom: 10px;
  display: inline-block;
}

.selectButton {
  margin-bottom: 10px;
  margin-left: 10px;
  display: inline-block;
}

.selectBox {
  border: 1px solid rgba(224, 224, 224, 1);
}

.collapse {
  cursor: pointer;
  vertical-align: middle;
}

.nameLabel {
  margin-bottom: 4px;
  padding-left: 6px;
  display: inline;
}

.subRow {
  background-color: #f4f6f7;
}

.breadcrumbItem {
  padding: 0.2rem;
  color: var(--color-light-blue);
}

.breadcrumbItem:hover {
  cursor: pointer;
  border-radius: 1rem;
  color: var(--color-light-blue);
  opacity: 0.6;
  transition: 0.3s;
}

.chip {
  border-radius: 10px;
  margin: 8px;
  background: #dddedf;
}

.chip > span {
  font-family: 'Poppins', sans-serif;
  color: #000;
}
