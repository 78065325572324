.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
}

.configurationList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--color-white);
  border-radius: 2rem;
}

.configurationLink {
  width: 100%;
  text-decoration: none;
  cursor: pointer;
}

.configurationItem {
  column-gap: 1rem;
  display: flex;
  justify-content: flex-start;
  margin-left: 35px;
}

.version {
  padding: 10px;
  color: #757575;
}

.configurationSeparator {
  margin: 0;
  width: 95%;
}

.configurationItemLabel {
  font-size: 1.2rem;
  text-align: left;
  display: block;
}

.icon {
  width: 30px;
}

@media (max-width: 600px) {
  .mainContainer {
    margin: 0 1rem;
  }
  .configurationSeparator {
    margin: 10px 0;
    color: #fff;
    border-color: #fff;
    width: 100%;
  }
  .configurationList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    background-color: var(--color-white);
    border-radius: 10px;
    padding: 1rem !important;
    border: 1px solid #68b3e0;
  }
}
