.title {
  border-bottom: 2px solid #f18b24;
  margin: 1.5rem;
}

.pageContainer {
  background: #ffffff;
  margin: 25px;
  box-shadow: -1px -5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 15px;
}

.pageContainer .box {
  display: flex;
  width: 100%;
}

.pageContainer .label {
  font-weight: bold;
  width: 10%;
}

.pageContainer .boxButtons {
  display: flex;
  margin-left: 83%;
  gap: 15px;
  justify-content: flex-end;
}

.row {
  display: flex;
  align-items: center;
}

input {
  min-height: auto !important;
}
