.boldText {
    font-weight: bold;
    font-family: "Poppins", sans-serif;
  }
  
  .commentText {
    margin-left: 8px;
  }
  
  .divider {
    background-color: #68b3e0;
    margin: 8px;
  }