.card {
  margin: 1rem;
}

.headerTitle,
.cellValue,
.headerName {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}

.headerTitle,
.cellValue {
  text-align: center;
}

.cellValue::first-letter {
  text-transform: uppercase;
}

.cellCenter {
  display: flex;
  justify-content: center;
}

.cellCheck {
  width: 10%;
}

.actionBtns {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 1rem;
}
