.header {
  margin-top: 0;
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 10px;
  border-bottom: 3px solid var(--color-light-blue);
  margin-bottom: 40px;
}

.box {
}

.buttonsNav {
  text-transform: none !important;
}

.textNav {
  font-family: Poppins, serif !important;
  font-size: 1.08rem;
  margin-left: 0;
}

.textField {
  margin-left: 30px;
  width: 100%;
}

.checkBox {
  margin-left: 20px;
  width: 100%;
}

.titleLabel {
  margin-right: 85px;
}

.descriptionLabel {
  margin-right: 33px;
}

.separator {
  margin: 0 10px 0;
}

b {
  padding-right: 10px;
}
