.searchContainer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.iconBody {
  vertical-align: middle;
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
}
.searcherFieldsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 1rem;
  width: 100%;
}

.searchField {
  width: 220px;
}

@media (max-width: 1024px) {
  .searchField {
    width: 135px;
  }
  .searchContainer form {
    width: 100%;
}
}

@media (max-width: 599px) {
  .searchContainer {
    justify-content: center; /* Centrar búsqueda en pantallas pequeñas */
    margin-right: 5px;
  }
  .searcherFieldsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .searchField {
    width: 40%;
  }

  .tableContainer {
    overflow-x: auto; /* Añadir scroll horizontal en pantallas pequeñas */
  }

  table {
    width: 100%;
    border-spacing: 0;
  }

  th,
  td {
    padding: 8px;
    text-align: left;
  }

  .paginationContainer {
    justify-content: center; /* Centrar paginación en pantallas pequeñas */
  }
}
