.mainContainer {
  width: 100%;
  height: 100%;
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mainContainerRow {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 1.5rem;
}

.profileContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 1px solid #0163a6;
  padding: 1rem 0;
}

.profileInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.title {
  margin-bottom: 4%;
  font-size: 24px !important;
  font-weight: bolder;
  color: #0163a6;
  font-family: 'Poppins', sans-serif;
}

.subtitle {
  font-size: 18px !important;
  font-weight: bold;
  color: #000;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
}

.text {
  font-size: 18px !important;
  color: #000;
  font-weight: normal;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
}
