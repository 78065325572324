.role {
  margin-bottom: 1rem;
}

.role > * {
  margin: 0.3rem;
}

.roleNumericDay {
  color: #fff;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  background-color: rgb(0, 98, 166);
  font-size: 20px;
}

.roleStringDays {
  padding-left: 20px;
  font-size: 22px;
  color: rgb(63, 80, 181);
}

.roleStringDays p {
  margin: 0;
  color: #0063a6;
  font-weight: 600;
}



.parentContainer {
  display: flex;
  justify-content: center; 

  
}

.weekDay {
  margin: -4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}



.listViewMobileContainer::after {
  display: block;
  content: '';
  width: 100%; 
  height: 2px;
  background: #f18b24;
  bottom: 0;
  left: 0;
  margin: 0 auto; 
}

.selected {
  color: #f18b24
}

.default {
  font-family: Montserrat;
  color: #b6b5b5; 
 
}


.listContainer {
  flex-direction: column;
  margin: 20px;
}

.eventHeader {
  border-bottom: solid 3px #68b3e0;
}

.event {
   border-bottom: 1px solid #d1d1d1; 
  cursor: pointer;
}

.event > * {
  padding: 1rem;
}

.selectedEvent {
  background-color: var(--color-selected-table-item);
}

.day > * {
  margin: 0.3rem;
}

.popoverPaperContainer {
  max-height: 50% !important;
  max-width: 60% !important;
}

.popoverEvent > * {
  margin: 1rem;
}

.popoverEventHeader {
  margin-bottom: 0;
}

.popoverEventHeader > * {
  margin: 1rem 1rem 0 0;
}

.popoverEventHoursInfoContainer > * {
  margin: 0.5rem;
}

.popoverButtons {
  display: flex;
  column-gap: 0.5rem;
}

.popoverEventCronTooltip {
  background-color: rgb(97, 97, 97) !important;
}

.popoverEventCronTooltip > p {
  color: #fff;
}

.popoverEventCategory {
  margin-bottom: 0;
}

.popoverEventInfo > * {
  flex-grow: 1;
  margin: 1rem;
}

.popoverEventTable {
  border: 1px solid black;
  border-collapse: collapse;
}

.popoverEventTable > tbody tr:nth-child(odd) {
  background-color: #dddddd;
}

.popoverEventTable tr {
  border: 1px solid black;
  text-align: center;
}

.alignTextCenter {
  padding: 0;
}

.noEvents {
  position: relative;
  top: 50%;
  text-align: center;
  margin: 0;
}

.eventModal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

@media (max-width: 400px) {
  .popoverEventHoursInfoContainer {
    flex-direction: column;
  }
}

/*mobile resolution*/
@media (max-width: 599px) {
  .listContainer {
    flex-direction: column;
    margin: 5px;
  }
}