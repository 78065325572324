.containerCalendar {
  display: flex;
  flex-direction: column;
  margin: 10%;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  width: 65vw;
  height:1500px;
}

.containerGroup {
  display: flex;
  flex-direction: column;
  margin: 10%;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  width: 65vw;
}

.modalOverFlow {
  display: flex;
  overflow: scroll;
  justify-content: center;
}

.contentHeader {
  display: flex;
  justify-content: flex-end;
  margin: 8px;
  color: #68b3e0;
}

.headerTitle {
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  font-size: 18px !important;
  padding: 15px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.addButton {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  margin-bottom: 20px;
}

.sportCenterInfo {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}


.field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8px;
  align-items: center;
}

.subFieldPrimero{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex-grow: 1;
  margin-right: 10px;
}

.subField {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex-grow: 1;
}

.inputStyle{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.fieldRow {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  margin: 8px;
}

.fieldTitle {
  font-family: 'Poppins', sans-serif !important;
  margin-right: 0 !important;
  width: 155px !important;
  padding-right: 0 !important;
}

.fieldTitleLongitude {
  font-family: 'Poppins', sans-serif !important;
  width: 175px ;
  padding-right: 0 !important;
  margin-right: 0 !important;
}

.icon {
  width: 30px;
  height: 30px;
  display: flex;
}

.iconEdit {
  display: flex;
  width: 25px;
  height: 25px;
}

.toolTipIconEmpty{
height: 10px !important;
}

.iconImage {
  width: 100%;
  margin-bottom: 5px;
}

.dividerStyle {
  margin-right: 15px;
  margin-left: 15px;
  border-color: #f47105;
  margin-bottom: 15px;
}

.modalNewTrainer {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.newTrainerContainer{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fefefe;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;

}

.actionBtns {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  padding: 5px
}

.fieldRowButton {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;

}

.newTrainerMessage {
  display: flex;
  align-items: center;
  margin: 20px 10px 20px 10px;
  flex-wrap: wrap;
}

.tooltipContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: auto;
  width: auto;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  padding: 1.5rem;
}

.tooltipTitle {
  position: relative;
  color: #68b3e0;
  font-size: 28px;
  font-family: 'Montserrat' !important;
  font-weight: bolder;
  text-transform: uppercase;
  display: block;
  word-break: break-word !important;
  margin: 1rem 0;
}

.tooltipTitle::after {
  content: '';
  position: absolute;
  left:0;
  bottom: 0;
  width: 20%;
  height: 2px;
  background-color: #68b3e0;
}

.tooltipTitleRow{
  display: flex;
  flex-direction: row;
}

.toolTipRow {
  display: flex;
  flex-direction: row;
  justify-content: start;
  color: black;
  font-size: large;
  margin-bottom: 5px;
}

.toolTipBold {
  font-size: large;
  font-weight: bold;
  margin-right: 5px;
}

.toolTipText{
  word-break: break-word !important;
}

.itemsRow{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
