.headerBtns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.actionBtns {
    display:none;
}

@media (max-width: 599px) {
    .actionBtns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
    }
}