.aside {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.listContainer {
  display: flex !important;
  flex-direction: column;
}

.list {
  display: flex !important;
  align-items: center !important;
  padding: 0 !important;
  width: 100% !important;
  justify-content: space-between !important;
}

.menuList > * {
  margin: 0 0 0 0;
}

.linkActive .menuItem {
  background-color: #fff;
}
.menuItem > * {
  margin-left: 20px;
  margin-right: 20px;
  height: 100%;
  justify-content: center;
}

.menuItemActive {
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff !important;
}

.circleSubMenuItem > * {
  justify-content: center;
  align-items: center;
  background-color: #ffffff !important;
}
.circleSubMenuItemActive {
  justify-content: center;
  align-items: center;
  background-color: #ffffff !important;
}

.menuItemProfile > * {
  justify-content: center;
}

.menuItemProfileActive {
  justify-content: center;
  align-items: center;
  background-color: #ffffff !important;
}

.menuDiv {
  display: flex;
  align-items: center;
  width: 100%;
}

.subMenuContainer {
  display: flex;
  width: 100vw;
  height: 200px;
  z-index: 1;
  background-color: #ffffff !important;
  padding-left: 100px;
  padding-top: 36px;
  margin-bottom: 52px;
}

.profileSubMenu {
  display: flex;
  width: 100vw;
  height: 240px;
  z-index: 1;
  background-color: #ffffff !important;
  padding-left: 45px;
  padding-top: 24px;
  margin-bottom: 52px;
}

.profileSubMenu h6 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #515151;
}

.subMenuContainer h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: justify;
  color: #0063a6;
  font-family: Montserrat, sans-serif !important;
  margin: 0;
}

.titleNameSubMenu {
  margin-top: 0 !important;
  font-family: Montserrat, sans-serif !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 17px;
    margin: 0 0 8px;
  color: #515151;
}

.notificationsNumber {
  font-style: italic;
  font-size: 13px;
  font-weight: bold;
  color: #F18B24;
}

.avatarContainer {
  margin-right: 32px;
  width: 80px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarContainerActive {
  margin-right: 32px;
  width: 80px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarContainerBackground {
  width: 50px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarContainerActiveBackground {
  width: 50px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.avatarImg {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.containerInfoUser {
  display: flex;
  flex-direction: column;
  margin-left: 42px;
}

.userName {
  font-family: Montserrat, sans-serif !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 17px;
  color: #515151;
  margin: 6px !important;
}

.userInfo {
  font-family: Montserrat, sans-serif !important;
  margin: 6px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #515151 !important;
}

.dividerUserProfile {
  margin: 0 49px 0 49px !important;
  border: 1px solid #0063a6 !important;
  height: 183px !important;
}

.link,
.link h3,
.link:visited {
  text-decoration: none;
  font-size: 15px;
  font-weight: normal;
  color: #454545;
  font-family: 'Montserrat', sans-serif;
}

.linkActive h3 {
  border-bottom: 2px solid #f37e31;
  background-color: #ffffff;
}
.linksMobileOpen {
  position: absolute;
  left: 0;
  top: 100%; /* Ajusta la posición vertical según tus necesidades */
  width: 100%;
  z-index: 1; /* Asegúrate de que el menú desplegable aparezca sobre el resto del contenido */
}

/* TMP TO DISABLED ELEMENTS */
.menuItemDisabled > * {
  margin: 0.8rem;
  opacity: 0.2;
}

.linkDisabled,
.linkDisabled h3 {
  text-decoration: none;
  pointer-events: none;
}

.menuIcon {
  width: 21px;
  margin-left: 40px;
}

.menuIcon {
  width: 21px;
}

.circleSubMenu,
.circleSubMenu h3 .circleSubMenu:visited {
  text-decoration: none;
  font-size: 15px;
  font-weight: normal;
  color: #454545;
  font-family: 'Montserrat', sans-serif;
}

.circleSubMenuActive h3 {
  color: #f37e31;
  background-color: #ffffff;
}

.progressContent {
  position: relative;
  margin-top: 20px;
  margin-right: 15px;
}

.progressPercent {
  position: absolute;
  right: -40px;
  top: 12px;
}
