.yearHeader {
  border: solid 1px #e3e3e3;
}

.yearContainer {
  display: flex;
  justify-content: center;
  color: #68B3E0;
  font-weight: bold;
  font-family: 'Poppins', sans-serif !important;
}

.monthRow {
  border: solid 1px #e3e3e3;
}

.monthContainer {
  display: flex;
  justify-content: left;
  color: #68B3E0;
  font-weight: bold;
  font-family: 'Poppins', sans-serif !important;
}

.monthEvent,
.monthEventInoculated {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 17px;
  margin: 0 0 2px 0;
  font-weight: bold;
  padding: 8%;
  font-family: 'Poppins', sans-serif !important;
}

.monthEvent, .monthEventMobile {
  background-color: #CF3031;
}


.monthEventInoculated, .monthEventInoculatedMobile {
  background-color: #6CE32B;
}

.monthEventInoculatedMobile, .monthEventMobile {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  z-index: 2;
  margin-bottom: 5px;
  margin-left: 50%;
}

.tableRows {
  border: solid 1px #e3e3e3;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
}

.mobileModalContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 700px;
  padding: 1.5rem;
  padding-bottom: 80px;
  background-color: var(--color-white);
  gap: 15px;
}

.mobileScrollContainer {
  max-height: 700px;
  overflow-y: scroll;
  position: relative;
  z-index: 20;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
}

.mobileScrollContainer > img {
  width: 100px;
}


@media (max-width: 400px) {
  .header {
    margin: 0;
    padding: 0;
  }

  .yearContainer {
    font-size: 11px;
  }

  .monthContainer {
    width: 40px;
    font-size: 8px;
  }

  .monthEvent {
    font-size: 7px;
    padding: 3%;
  }
}

@media (min-width: 401px) {
  .yearContainer {
    font-size: 15px;
  }

  .monthContainer {
    font-size: 13px;
  }

  .monthEvent {
    font-size: 10px;
    padding: 3%;
  }

  .listContainerGreen, .listContainerRed{
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    width: 85vw;
  }
  
  .listContainerGreen {
    background-color: #6be32b2f;
    border: 2px solid #6CE32B;
  }
  
  .listContainerRed {
    background-color: #cf303033;
    border: 2px solid #CF3031;
  }
}

@media (min-width: 1280px) {
  .yearContainer {
    font-size: 1.5rem;
  }

  .monthContainer {
    font-size: 1rem;
  }

  .monthEvent {
    font-size: 10px;
  }
}

