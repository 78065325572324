.modal {
  display: flex;
  justify-content: center;
  margin: 2rem;
  overflow: auto;
}

.modalContainer {
  overflow: auto;
  width: 800px;
  height: fit-content;
  flex-shrink: 0;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 1.5rem;
  background: #ffffff;
  margin: 25px;
}

.icon {
  cursor: pointer;
}

.autocompleteStyle {
  width: inherit;
  max-width: auto !important;
  font-family: 'Open-Sans', sans-serif !important;
}

.inputsRow {
  display: flex;
  gap: 24px;
  margin: 24px 0;
  align-items: center;
}

.inputBox {
  flex: 1;
}

.symptomLabel {
  font-family:
    Open Sans,
    sans-serif;
  white-space: nowrap;
}

.commentBox {
  margin-bottom: 24px;
}

.errorBox {
  margin-bottom: 13px;
}

.formBox {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.buttonsContainer {
  display: flex;
  margin-top: auto;
  justify-content: space-between;
}

.exitIcon {
  display: flex;
  justify-content: flex-end;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media (max-width: 1300px) {
  .autocompleteStyle {
    width: auto;
  }

  .inputsRow {
    flex-direction: column;
    gap: 24px;
  }

  .inputBox {
    flex-direction: column;
    width: 100%;
    display: flex;
  }
}

@media (max-width: 598px) {
  .modal {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
  }
  .modalContainer {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    margin: 0;
    border-radius: 0;
  }

  .inputsRow {
    flex-direction: column;
  }
}
