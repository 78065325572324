.divHeader {
  background-color: white;
  padding: 7px;
  border-bottom: 3px solid #f18b24;
}

.divHeaderLeft > * {
  margin-left: 1rem;
}

.contactFullName {
  margin-right: 1rem;
}

.tooltipConversationUsers {
  margin-left: 1rem;
}

.conversationLeft {
  flex-flow: column;
  align-items: end;
}

.conversationLeft p {
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.conversationOptions {
  justify-content: flex-end;
  padding-bottom: 0;
  width: 100%;
}

.conversationOptions > div {
  width: 2rem;
  display: flex;
  justify-content: center;
}

.MuiAvatar-fallback {
  background-color: #68b3e0;
}

/*resolucion movil*/
@media (max-width: 599px){
  .divHeader {
    background-color: white;
    padding: 7px;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: var(--color-orange);
  }
}
