.container {
    max-height: 200px;
    overflow-y: auto;
    cursor: pointer;
  }


.chatContainer{
    display: flex;
    align-items: center;
    border: 2px solid #68B3E0;
    border-radius:10px;
    box-shadow: 0px 4px 4px 0px #00000040 !important;
    cursor: pointer;
    background: white;
    margin-bottom: 0.75rem;
    width: 100%;
    padding: .90rem 1rem!important;
    gap: 0.5rem;
    height: 52px;
}


.chatContainer:last-child {
    margin-bottom: 0!important;
}


.listItemText{
    font-family: 'Poppins', sans-serif !important;
    text-transform: capitalize;
}

.chatContainerActive {
    background: rgb(241, 139, 36, 0.10);
    border-color: rgb(241, 139, 36);
}

.circle {
    width: 70%;
}
.circle > span {
    font-weight: bold;
}

.user {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
}

.icon {
    width: 20px;
    height: 20px;
}

.text > span {
    font-size: 14px!important;
}

.text > span,
.circle > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}