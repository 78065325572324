.btnAttachFile {
  display: flex;
  align-items: center;
}

.btnAttachFileActive {
  cursor: pointer;
}

.btnAttachFileDisabled {
  opacity: 0.8;
  filter: grayscale(1);
}
.btnAttachFileDisabled > span {
  cursor: auto;
  user-select: none;
}

.attachIconRotate {
  transform: rotate(-90deg);
}

.disabled {
  filter: grayscale(1);
}
