.tableContainerMob {
  display: flex;
  margin: 0px 5px 0px 5px !important;
  width: 100%;
  height: 100%;
}

.tableContainer {
  table-layout: fixed;
  width: 96% !important;
  margin: 0 2% 0 2% !important;
  border: 1px solid #d1d1d1;
}

.hourRow {
  height: 55px !important;
  font-size: 18px !important;
  padding: 0 !important;
  font-family: 'Poppins', sans-serif !important;
  text-align: center !important;
}
.hour {
  color: #68b3e0;
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold;
}

.eventRow {
  border-bottom: 1px solid #d1d1d1;
  border-left: 1px solid #eae9ee;
  font-family: 'Poppins', sans-serif !important;
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
}

.eventRowGroup {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d1d1d1;
  border-left: 1px solid #eae9ee;
  font-family: 'Poppins', sans-serif !important;
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
}

.weekHeader {
  font-family: 'Poppins', sans-serif !important;
  border: 1px solid #e0e0e0ff;
  width: 120px;
  border-bottom: none;
}

.weekHeader {
  font-family: 'Poppins', sans-serif !important;
  width: auto;
}

.weekDay {
  padding: 0 0 0 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  color: #68b3e0;
  font-family: 'Poppins', sans-serif !important;
}

.weekDayMobile {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  font-family: 'Poppins', sans-serif !important;
  width: 50px;
}
.hourMobile {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  font-family: 'Poppins', sans-serif !important;
}
.dayNumber {
  margin: auto;
  padding: 0.6rem;
  font-size: 20px;
}

.event {
  position: relative;
  cursor: pointer;
  text-align: left;
  font-size: 0.7rem;
  border-radius: 3px;
  height: auto;
  --duration-height: 0;
  border: 1px solid #ffffff;
}

.event:after {
  position: absolute;
  content: '';
  top: calc(100% - 3px);
  left: 0;
  right: 0;
  height: calc(var(--duration-height) * 55px);
  border-radius: 0 0 3px 3px;
}

.eventTitle {
  color: #ffffff;
  margin: 0 !important;
}

.event > * {
  padding: 0.5rem;
  justify-content: center;
}

.popoverPaperContainer {
  max-height: 50% !important;
  max-width: 60% !important;
}

.popoverEvent > * {
  margin: 1rem;
}

.popoverEventHeader {
  margin-bottom: 0;
}

.popoverEventHeader > * {
  margin: 1rem 1rem 0 0;
}

.popoverEventHoursInfoContainer > * {
  margin: 0.5rem;
}

.popoverButtons {
  display: flex;
  column-gap: 0.5rem;
}

.popoverEventCronTooltip {
  background-color: rgb(97, 97, 97) !important;
}

.popoverEventCronTooltip > p {
  color: #fff;
}

.popoverEventCategory {
  margin-bottom: 0;
}

.popoverEventInfo > * {
  flex-grow: 1;
  margin: 1rem;
}

.popoverEventTable {
  border: 1px solid black;
  border-collapse: collapse;
}

.popoverEventTable > tbody tr:nth-child(odd) {
  background-color: #dddddd;
}

.popoverEventTable tr {
  border: 1px solid black;
  text-align: center;
}

.eventModal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar {
  width: 100%;
  border-style: solid;
  border-radius: 20px;
  border-color: #68b3e0;
  border-width: 2px;
  padding: 20px 0px 20px 0px;
  overflow-x: auto;
}

.headerCalendar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerCalendar2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  padding-right: 10px;
  margin-right: 20px;
}

.groupRow {
  padding: 0;
  width: 100%;
  height: 100%;
}

.groupColor {
  height: 55px;
  width: 100%;
}

.tabCelGroupRow {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.groupTitleColor {
  display: block;
  height: 20px;
  width: 20px;
  margin-left: 10px;
}

.rowTimeTableTitle {
  display: flex;
  flex-direction: row;
  justify-content: end;
  flex-wrap: wrap;
}

.groupTitleName {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  margin-left: 10px;
  margin-right: 10px;
}

/*mobile resolution*/
@media (max-width: 599px) {
  .eventRow {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    font-family: 'Poppins', sans-serif !important;
    padding: 0 !important;
    margin: 0 !important;
    height: auto;
    width: 80px !important;
  }

  .scrollableContainer {
    display: flex;
    overflow-x: scroll;
  }

  .event {
    position: relative;
    cursor: pointer;
    text-align: left;
    font-size: 0.7rem;
    border-radius: 3px;
    height: auto;
    --duration-height: 0;
    border: 1px solid #ffffff;
  }

  .weekHeader {
    font-family: 'Poppins', sans-serif !important;
    border: 1px solid #e0e0e0ff;
    border-bottom: none;
  }

  .weekDay {
    padding: 0 0 0 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
    color: #68b3e0;
    font-family: 'Poppins', sans-serif !important;
    width: 80px;
  }
}
