.loadingSpinner {
  position: absolute;
  right: 40%;
  bottom: 30%;
}

.loadingResourceSpinner {
  position: absolute;
  right: 60%;
  bottom: 50%;
}

.loadingSpinnerCalendar {
  position: absolute;
  right: 35%;
  bottom: 35%;
}
