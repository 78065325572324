.card {
  height: 100%;
  margin-right: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 7px !important;
}

.containerCard {
  display: flex;
  flex-direction: column;
}

.rowSpaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.adverseEffectsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.adverseEffectsItem{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #68B3E0;
  padding: 10px 0;
}

.pieGrid canvas{
  max-height: 300px;
}