.containerList{
  min-height: 400px;
  cursor: pointer;
  max-height: 400px; 
  overflow-y: auto; 
}

.icon {
  height: 35px;
}

.divider{
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  background-color: #AFD7EF !important;
  width: 100% !important
}

.listItemText{
  font-family: 'Poppins', sans-serif !important;
  text-transform: capitalize;
}

.image{
  width: 100px;
  margin-right: 10px;
}

.title{
  color:#7fb6de
}

.titleEditor {
  padding: 0 10px;
  font-weight: bold !important;

  span:last-child{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
