.title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  border-bottom-color: #ee8c38;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  padding: 0 0 10px 0;
  margin: 0 0 20px 0;
}

.buttonsContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.buttons {
  padding: 30px !important;
  background-color: #67b3e0 !important;
  border-radius: 0 10px 10px 0 !important;
  color: #fff !important;
  font-size: 1rem !important;
  font-family: 'Poppins', sans-serif !important;
  text-transform: none !important;
}

.imageButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0163a6;
  width: 80px;
  height: 80px;
  border-radius: 10px 0 0 10px !important;
}

.textFieldBox {
  display: flex;
  justify-content: flex-start;
  gap: 40px;
}

.cipContainer {
  display: flex;
  width: max-content;
}

.homeLabel {
  font-weight: bold;
  margin-left: 0 !important;
}

.hoverCard {
  border-bottom-width: 2px;
  transition: border-bottom-color 0.3s ease;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  padding: 2rem;
  border-radius: 8px;
  border-color: #67b3e0 !important;
  box-shadow: 0px 4px 5px 0px #00000026;

}

.hoverCard:hover {
  border-color: #67b3e0;
  border-width: 2px;
  background: #67b3e00d;
}

.horizontalLine {
  height: 3px;
  width: 40%;
  background-color: #f18b24;
  border: none;
  margin-left: 0px;
}

.textBlock {
  width: 70%;
  font-size: 1.4em;
}

.chip {
  background-color: #0063a6 !important;
  color: white !important;
  padding: 0px 9px !important;
  border-radius: 17px !important;
  margin: 4px !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 0.9rem !important;
}
