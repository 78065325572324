.pageContainer {
  background: #ffffff;
  margin: 0 25px 25px 25px;
  box-shadow: -1px -5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 8px;
  padding: 15px;
}

.centerCell {
  text-align: center !important;
}

.headerCell {
  font-weight: bold !important;
  background-color: #0063a6;
  color: #ffffff !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.centerCellButton {
  text-align: center !important;
  display: block ruby !important;
}

.pointer {
  cursor: pointer;
}

.tab {
  margin-right: 2em;
  margin-left: 1.5625em;
  padding: 0 !important;
}
.tabs {
  text-transform: none;
  background-color: #cccccc !important;
  font-size: 16px !important;
  font-style: normal !important;
  line-height: normal !important;
  font-family: Montserrat, sans-serif !important;
  color: #282828 !important;
}
.tabsActive{
  font-size: 16px !important;
  font-weight: 600 !important;
  text-transform: none;

  background-color: white !important;
  color: #515151 !important;
  font-style: normal !important;
  line-height: normal !important;
  font-family: Montserrat, sans-serif !important;
}
.noElements {
  font-size: 3rem;
  text-align: center;
}

.buttonContainer {
  display: flex;
  column-gap: 1rem;
  justify-content: end;
}

.currentElement {
  background-color: var(--color-selected-table-item);
}

.tableRow:hover,
.tableRow:active {
  background-color: var(--color-selected-table-item);
}

.genericFlexContainerSpacing {
  column-gap: 1rem;
  row-gap: 1rem;
  cursor: pointer;
}

.paginationContainer {
  width: 100%;
  align-items: center;
  display: flex;
}

.pagination {
  margin: auto;
  padding-top: 10px;
}

.loadingSpinner {
  position: absolute;
  right: 40%;
  bottom: 40%;
}

.eventModal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
}

.sharedModal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.accordionDetails {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@media (max-width: 600px) {
  .pageContainer {
    margin: 10px;
  }
  .buttonContainer {
    height: 70px;
  }
}
