.root {
  float: left;
  margin-right: 5%;
}

.selectWeekDay {
  width: 12rem;
}

.prueba {
  display: inline;
}

.botones {
  display: block;
  float: right;
  width: 85%;
  height: 0;
}

.inputWeek {
  min-width: 60%;
  max-width: 60%;
}

.cronExpressionContainer {
  padding: 0 !important;
}

.cronContainer {
  column-gap: 1rem;
  padding: 1rem;
  border: 1px solid var(--color-light-blue);
}

.mockupCronContainer {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  padding: 1rem;
  border: 1px solid var(--color-light-blue);
  border-radius: 10px;
}
.eventParraf{
  color: #515151;
  text-align: center;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.046px;
}

.labelWithoutEndDate{
  justify-content: center;
  align-items: center;
  display: flex;

}
.labelWithoutEndDate > span{
  color: #515151;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.046px;
}
.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  color: #515151;
  text-align: center;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.046px;
}

.monthlyContent{
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.046px;
}

.mockupCronTypeContainer {
  border-right: 1px solid var(--color-light-blue);
}

.mockupRepeatingContainer {
  width: 30%;
  border-left: 1px solid var(--color-light-blue);
  color: #515151;
  text-align: center;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.046px;
}

.mockupSelect {
  min-width: 100% !important;
}

.interactiveButtonsContainer {
  row-gap: 1rem;
}
