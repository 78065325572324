.divSender {
  background-color: rgb(255, 255, 255);

}

.formSendMessage {
  display: flex;
  align-items: stretch;
  width: 100%;
}

.formSendMessageDisabled {
  opacity: 0.4;
}

.divMessage {
  border: 1px solid rgb(104, 179, 224);
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  margin: 1rem;
  padding: 0.3rem;
  width: 100%;
}

.btnEmoji {
  cursor: pointer;
  margin-left: 0.5rem;
}

.inputMessage {
  flex-grow: 1;
  margin-left: 1rem;
  margin-right: 0.2rem;
  font-size: 22px !important;
  resize: none;
}



.divSendFile,
.divSendFile > * {
  cursor: pointer;
  

}

.btnSendFile { /*! ruptura*/
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-basis: 100%; */

}

input[type='file'],
input[type='submit'] {
  display: none;

}

.emojiPicker {
  position: relative;
  top: -390px;
  left: 80px;
  z-index: 1;
}

.attachedElement {
  display: flex;
  align-items: center;
}

.attachedElement > p {
  margin: 0;
}

.removeAttachedElement {
  cursor: pointer;
  margin-right: 1rem;
}

.btnDisabled {
  cursor: auto !important;
  opacity: 0.3;
}

/* resolicion movil */
@media(max-width: 599px){
  .formSendMessage {
    display: flex;
    align-items: stretch;
    width: 100%;
    background-color: white;

  }

  .divMessage {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    margin: 0.1rem 0 0.1rem 0.1rem;
    padding: 0.1rem;
    border-style: solid;
    border-width: 1px;
    border-color:  var(--color-dark-blue);
    border-radius: 20px;
    width: 100%;
  }

  .rowContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 10px;
  }

  .iconSender{
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}
