.map {
  background-color: #ffffff;
  font-size: calc(10px + 2vmin);
  height: 100%;
  width: 100%;

}

.ol-unselectable {
  display: block !important;
}

.ol-layers {
  display: block !important;
}

.olPopup {
  position: absolute;
  background-color: white;
  padding: 15px;
  border-radius: 10px !important;
  border: 1px solid #3d87ba;
  bottom: 40px;
  left: -50px;
  min-width: 280px;
  font-size: 10px;
  display: none;
}

.olPopup::after,
.olPopup::before {
  top: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.olPopup::after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.olPopup::before {
  border-top-color: #3d87ba;
  left: 48px;
  margin-left: -11px;
}

.olPopupCloser {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.olPopupCloser::after {
  content: '✖';
  font-size: 25px;
}

@media (max-width: 900px) {
  .map {
    box-shadow: none;
    padding: 5px;
    margin: 0;
  }
}

@media (max-width: 1230px) {
  .map {
    box-shadow: none;
    height: 450px;
    width: 100%;
    padding: 0 !important;
    margin: 0;
  }

  .olPopup {
    position: absolute;
    background-color: white;
    padding: 10px;
    border-radius: 10px !important;
    border: 1px solid #3d87ba;
    bottom: 40px;
    left: -65px;
    width: 280px;
    font-size: 10px;
    display: none;
  }
}

.wallContainer {
    display: flex;
    flex-direction: row;
    background-color: #fafafa;
    height: 80%;
}

.notificationContainer {
    overflow: auto;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    margin-bottom: 20px;
    border-radius: 2px;
    height: 100%;
    width: 100%;
    max-height: 100%;
}

.audivisualContent {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    margin-bottom: 20px;
    border-radius: 2px;
    height: 60%;
    width: 100%;
}

.horizontalTimeline {
    height: 100%;
    width: 100%;
    max-height: 100%;
}

.test2 {
    overflow: auto;
    margin-top: 0;
    padding-top: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row !important;
    width: 100%;
    height: 90%;
}

.chatWidgetContainer {
    background-color: #ffffff;
    padding: 20px;
    margin: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    width: 30%;
    max-height: 100%;
}

.screen{
    width: 90%;
    align-content: center;
    text-align: center;
}

.scree-Icon{
    color:rgb(0, 69, 116)
}

.test1 {
    width: 90%;
    height: 100%;
    /* border: 1px solid red;*/
}

.chatIcon {
    width: 35px;
}

.arrowRight {
    width: 13px;
    margin: 0 5px 0 5px;
}

.noNotifications {
    text-align: center;
    font-size: 1.8rem;
}

.pagination {
    margin-right: auto;
    margin-left: auto;
}

.icon {
    width: 25px;
}

.containerFooter {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;
    color: #68b3e0;
}

.containerFooter h3 {
    font-size: 14px;
    font-weight: normal;
    color: #68b3e0;
    text-decoration: none;
    transition: all ease 0.4s;
}

.notificationBox {
    padding: 10px;
    height: 100% !important;
    max-height: 100%;
    /*border: 1px solid green;*/
    display: flex;
    width: 50vh;

    flex-direction: column; /* Added */
}

.conversationWall {
    width: 99%;
    margin-top: 15px;
    background-color: #ffffff;
    cursor: pointer;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    /*border: 1px solid #68b3e0;*/
}

.notificationTitle {
    margin: 5px auto 1px 10px;
    font-size: 20px;
}

.notificationsWall {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
    background-color: #ffffff;
    cursor: pointer;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    /*border: 1px solid #68b3e0;*/
}

.blueSeparator{
    border-bottom: 1px solid #68b3e0;
    margin: 1px 10px 1px 5px;
}

.avatarWrapper {
    /*display: flex;*/
    margin: 1px 1px 10px 5px;
}

.resendButton {
    display: flex;
    justify-content: flex-end;
}

.notificationDescription{
    margin: 5px auto 1px auto;
}

.notificationBlock {
    margin: 10px auto 1px 10px;
    width: 100%;
}

/*.notificationContainer {*/
/*    background-color: #ffffff;*/
/*    padding: 20px;*/
/*    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);*/
/*    margin-bottom: 20px;*/
/*    border-radius: 2px;*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    max-height: 100%;*/
/*}*/

.timelineDot {
    width: 25px;
    height: 25px;
    border-width: 4px;
}

@media (max-width: 1200px) {
    .wallContainer {
        flex-direction: column;
    }

    .notificationBox {
        width: auto;
    }

    .notificationContainer {
        margin: 10px 0 0 0;
        width: auto;
    }

    .chatWidgetContainer {
        margin: 0 0 10px 0;
        width: auto;
    }
}

@media (max-width: 600px) {
    .notificationBox h2 {
        justify-content: left;
    }
}

@media (max-width: 410px) {
    .containerFooter {
        display: flex;
        flex-flow: column;
        align-items: end;
    }
}
