.formEditorField {
  row-gap: 0.5rem;
  padding: 1rem;
  border: 2px solid #68b3e0;
  /*background-color: rgba(196, 224, 241, 0.09);*/
  border-radius: 10px;
}

.sectionLabel {
  font-weight: bold;
  font-size: 18px;
  color: var(--color-dark-blue);
}

.formBox {
  display: inline !important;
}

.requiredLabel {
  margin-left: 0 !important;
}

.deleteButton {
  display: block;
  width: fit-content;
  margin: auto 0 1rem !important;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.listItem {
  margin-bottom: 5px;
  width: 100%;
}

.tooltip {
  display: inline-flex;
}

.closeIcon {
  cursor: pointer;
}

.textField {
  width: 100%;
}

.label {
}

.labelType {
  margin-top: 4px;
}

.sameLine {
  margin-left: 30px;
}

.typeBox {
  margin-left: 9px;
}

.type {
  width: 300px;
  margin-bottom: 10px;
}

.optionBox {
  width: 100%;
  margin-left: 30px;
}

.scoreBox {
  width: 100%;
  margin-left: 20px;
}

.box {
  width: 100%;
}
